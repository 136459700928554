'use client'

import { FC } from 'react'
import { RecipientType } from '../../../../lib/graphql/generated/types'
import { useTranslation, useViewerUser } from '../../../../lib/hooks'
import { EventQueryEvent } from '../../../../lib/types'
import { Avatar, Button, ButtonTheme } from '../../../base'
import { ContactForm } from '../../../common'
import InformationItem from '../informationItem'

interface Props {
    eventId: string
    organizer: NonNullable<EventQueryEvent['organizerUsers']>[number]
    viewerParticipant: EventQueryEvent['viewerParticipant']
}

const OrganizerUser: FC<Props> = props => {
    const { t } = useTranslation()
    const viewerUser = useViewerUser()

    return (
        <InformationItem
            media={
                <Avatar
                    firstName={props.organizer.firstName}
                    image={props.organizer.thumbnail?.thumbnail?.resource}
                    lastName={props.organizer.lastName}
                />
            }
        >
            {props.organizer.displayName}
            <br />
            <ContactForm
                email={props.viewerParticipant?.email ?? viewerUser?.email}
                eventId={props.eventId}
                firstName={
                    props.viewerParticipant?.firstName ?? viewerUser?.firstName
                }
                lastName={
                    props.viewerParticipant?.lastName ?? viewerUser?.lastName
                }
                recipientId={props.organizer.id}
                recipientType={RecipientType.User}
            >
                {open => (
                    <Button onClick={open} theme={ButtonTheme.Transparent}>
                        {t('event:information.contact')}
                    </Button>
                )}
            </ContactForm>
        </InformationItem>
    )
}

export default OrganizerUser
