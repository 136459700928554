import * as Types from './types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export const AdditionalFieldsFragmentDoc = gql`
    fragment AdditionalFields on AdditionalField {
  label
  ... on AdditionalMultilineField {
    text
  }
  ... on AdditionalEmailField {
    email
  }
  ... on AdditionalLinkField {
    text
    url
    target
  }
  ... on AdditionalImageListField {
    images {
      title
      caption
      thumbnail(width: 2712) {
        resource
      }
    }
  }
  ... on AdditionalFileListField {
    files {
      title
      caption
      resource
    }
  }
}
    `;
export const InputValueFragmentDoc = gql`
    fragment InputValue on InputFieldValue {
  inputField {
    identifier
    label
  }
  ... on StringInputFieldValue {
    value: stringValue
  }
  ... on ListStringInputFieldValue {
    values: stringValues
  }
  ... on BooleanInputFieldValue {
    selected: booleanValue
  }
  ... on DateInputFieldValue {
    date: dateValue
  }
  ... on FileInputFieldValue {
    file: fileValue {
      title
      resource
    }
  }
  ... on ImageInputFieldValue {
    image: imageValue {
      title
      thumbnail(width: 64, height: 64) {
        resource
      }
    }
  }
}
    `;
export const CompanionFragmentDoc = gql`
    fragment Companion on Companion {
  id
  displayName
  inputFieldValues {
    ...InputValue
  }
}
    ${InputValueFragmentDoc}`;
export const GroupBookingFragmentDoc = gql`
    fragment GroupBooking on GroupBooking {
  id
  name
  description
  public
  participants {
    id
    displayName
  }
  primaryParticipant {
    id
    displayName
    userId
  }
  participantCount
  invitedParticipants {
    id
    displayName
  }
}
    `;
export const OnlineEventSettingsFragmentDoc = gql`
    fragment OnlineEventSettings on OnlineEventSetting {
  id
  timing
  url
  ... on InternalOnlineEventSetting {
    description
    fileExtension
  }
  ... on ExternalOnlineEventSetting {
    description
  }
}
    `;
export const InputFragmentDoc = gql`
    fragment Input on InputField {
  ... on StaticTextField {
    identifier
    label
    text
  }
  ... on SingleLineTextField {
    identifier
    label
    defaultValue
    placeholder
    required
    validator
  }
  ... on MultiLineTextField {
    identifier
    label
    defaultValue
    placeholder
    required
    validator
  }
  ... on SingleSelectRadiobuttonsField {
    identifier
    label
    options {
      label
      value
      selected
    }
    required
  }
  ... on CheckboxField {
    identifier
    label
    defaultCheckedValue: defaultValue
    required
  }
  ... on MultiSelectCheckboxesField {
    identifier
    label
    options {
      label
      value
      selected
    }
    required
  }
  ... on SingleSelectDropdownField {
    identifier
    label
    options {
      label
      value
      selected
    }
    required
  }
  ... on DatePickerField {
    identifier
    label
    defaultDateValue: defaultValue
    required
  }
  ... on FileUploadField {
    identifier
    label
    required
  }
  ... on ImageUploadField {
    identifier
    label
    required
  }
}
    `;
export const OperationFragmentDoc = gql`
    fragment Operation on Operation {
  id
  title
  description
  operationDates {
    startDate
    endDate
  }
  online
  onlineEventSettings {
    ...OnlineEventSettings
  }
  venue {
    name
  }
  price
  marketPrice
  priceBreakdown
  capacity {
    available
    minimum
    maximum
  }
  waitinglist {
    capacity {
      available
      minimum
      maximum
    }
  }
  viewerBookable {
    waitinglist
    minimum
    maximum
    maximumCompanions
  }
  inputFields {
    ...Input
  }
  groupBookingDefinition {
    groupCapacity {
      available
      minimum
      maximum
    }
    minimumGroupParticipants
    maximumGroupParticipants
    viewerGroupBookable {
      maximum
    }
  }
  groupBookings(limit: {length: 100}) {
    itemCount
    totalCount
    items {
      ...GroupBooking
    }
  }
  parentId
}
    ${OnlineEventSettingsFragmentDoc}
${InputFragmentDoc}
${GroupBookingFragmentDoc}`;
export const BookingFragmentDoc = gql`
    fragment Booking on Booking {
  id
  bookingNr
  bookingStatus
  creationDate
  includingVat
  deletable
  discount
  discountVatPrice
  registrationPrice
  registrationVatPrice
  eTicketUrl
  participant {
    id
    displayName
    email
    inputFieldValues {
      ...InputValue
    }
    public
    representative {
      ...Companion
    }
  }
  payment {
    paymentType {
      name
      gateway
    }
    onlinePaymentRequired
    commissionRate
    commissionPrice
    commissionVatPrice
    totalPrice
    totalVatPrice
    vatRate
    paymentStatus
    totalPriceInclVatRounded
    currency {
      name
    }
  }
  bookingOperations {
    id
    bookingOperationStatus
    groupBooking {
      ...GroupBooking
    }
    inputFieldValues {
      ...InputValue
    }
    operation {
      ...Operation
    }
    companion {
      ...Companion
    }
  }
  groupedBookingOperations {
    count
    bookingOperationStatus
    vatRate
    discount
    discountVatPrice
    discountPercentage
    totalPrice
    totalVatPrice
    operation {
      ...Operation
    }
    operationPrice
    bookingOperations {
      id
      bookingOperationStatus
      groupBooking {
        ...GroupBooking
      }
      inputFieldValues {
        ...InputValue
      }
      companion {
        ...Companion
      }
    }
  }
  inputFieldValues {
    ...InputValue
  }
}
    ${InputValueFragmentDoc}
${CompanionFragmentDoc}
${GroupBookingFragmentDoc}
${OperationFragmentDoc}`;
export const BrandingFragmentDoc = gql`
    fragment Branding on BrandingSetting {
  logo {
    originalWidth: width
    originalHeight: height
    thumbnail(height: 120) {
      resource
    }
  }
  primaryColor
  secondaryColor
  backgroundColor
  fontFile
  fontFamily
}
    `;
export const EventTeaserFragmentDoc = gql`
    fragment EventTeaser on EventTeaser {
  id
  key
  title
  lead
  timezone {
    name
  }
  startDate
  endDate
  image {
    thumbnail(width: 640, height: 360) {
      resource
    }
  }
  capacity {
    count
    maximum
    available
    waitinglist
  }
  lowestPrice
  currencyName
  online
  promoted
  venueName
  eventTypeKey
  eventTypeName
  operations {
    id
    title
    operationDates {
      startDate
      endDate
    }
    online
  }
}
    `;
export const ImageFragmentDoc = gql`
    fragment Image on Image {
  title
  caption
  thumbnail {
    width
    height
    resource
  }
}
    `;
export const RegisterOperationFragmentDoc = gql`
    fragment RegisterOperation on Operation {
  id
  title
  description
  operationDates {
    startDate
    endDate
  }
  online
  onlineEventSettings {
    ...OnlineEventSettings
  }
  venue {
    name
  }
  price
  marketPrice
  priceBreakdown
  capacity {
    available
    minimum
    maximum
  }
  waitinglist {
    capacity {
      available
      minimum
      maximum
    }
  }
  viewerBookable(ignoreParticipantBookings: true) {
    waitinglist
    minimum
    maximum
    maximumCompanions
  }
  inputFields {
    ...Input
  }
  groupBookingDefinition {
    groupCapacity {
      available
      minimum
      maximum
    }
    minimumGroupParticipants
    maximumGroupParticipants
    viewerGroupBookable(ignoreParticipantBookings: true) {
      maximum
    }
  }
  groupBookings(limit: {length: 100}) {
    itemCount
    totalCount
    items {
      ...GroupBooking
    }
  }
  parentId
}
    ${OnlineEventSettingsFragmentDoc}
${InputFragmentDoc}
${GroupBookingFragmentDoc}`;
export const AcceptInviteeDocument = gql`
    mutation AcceptInvitee($token: String!, $eventId: ID!) {
  viewer(token: $token) {
    event(id: $eventId) {
      viewerInvitee {
        accept {
          inputError {
            message
          }
          object {
            inviteeStatus
          }
        }
      }
    }
  }
}
    `;

export function useAcceptInviteeMutation() {
  return Urql.useMutation<Types.AcceptInviteeMutation, Types.AcceptInviteeMutationVariables>(Types.AcceptInviteeDocument);
};
export const AccessCodeLoginDocument = gql`
    mutation AccessCodeLogin($accessCode: String!, $eventId: ID!) {
  authentication {
    createTokenByAccessCode(
      accessCode: {accessCode: $accessCode, eventId: $eventId}
    ) {
      token
    }
  }
}
    `;

export function useAccessCodeLoginMutation() {
  return Urql.useMutation<Types.AccessCodeLoginMutation, Types.AccessCodeLoginMutationVariables>(Types.AccessCodeLoginDocument);
};
export const AddGroupBookingParticipantDocument = gql`
    mutation AddGroupBookingParticipant($token: String!, $eventId: ID!, $groupBookingId: ID!, $participantUsernameOrEmail: String!) {
  viewer(token: $token) {
    event(id: $eventId) {
      viewerParticipant {
        addGroupBookingParticipant(
          groupBookingParticipant: {id: $groupBookingId, participantUsernameOrEmail: $participantUsernameOrEmail}
        ) {
          inputError {
            message
          }
        }
      }
    }
  }
}
    `;

export function useAddGroupBookingParticipantMutation() {
  return Urql.useMutation<Types.AddGroupBookingParticipantMutation, Types.AddGroupBookingParticipantMutationVariables>(Types.AddGroupBookingParticipantDocument);
};
export const CancelBookingDocument = gql`
    mutation CancelBooking($token: String!, $eventId: ID!, $bookingId: ID!) {
  viewer(token: $token) {
    event(id: $eventId) {
      viewerParticipant {
        cancelBooking(id: $bookingId) {
          inputError {
            message
          }
        }
      }
    }
  }
}
    `;

export function useCancelBookingMutation() {
  return Urql.useMutation<Types.CancelBookingMutation, Types.CancelBookingMutationVariables>(Types.CancelBookingDocument);
};
export const CheckInParticipantDocument = gql`
    mutation CheckInParticipant($token: String, $eventId: ID!, $onlineEventSettingId: ID!) {
  viewer(token: $token) {
    event(id: $eventId) {
      viewerParticipant {
        checkInByOnlineEventSetting(id: $onlineEventSettingId) {
          inputError {
            message
          }
        }
      }
    }
  }
}
    `;

export function useCheckInParticipantMutation() {
  return Urql.useMutation<Types.CheckInParticipantMutation, Types.CheckInParticipantMutationVariables>(Types.CheckInParticipantDocument);
};
export const ConfirmBookingDocument = gql`
    mutation ConfirmBooking($token: String!, $eventId: ID!, $bookingId: ID!) {
  viewer(token: $token) {
    event(id: $eventId) {
      viewerParticipant {
        confirmBooking(id: $bookingId) {
          inputError {
            message
          }
          object {
            bookingStatus
          }
        }
      }
    }
  }
}
    `;

export function useConfirmBookingMutation() {
  return Urql.useMutation<Types.ConfirmBookingMutation, Types.ConfirmBookingMutationVariables>(Types.ConfirmBookingDocument);
};
export const ConfirmBookingOperationsDocument = gql`
    mutation ConfirmBookingOperations($token: String!, $eventId: ID!, $bookingOperationIds: [ID!]!) {
  viewer(token: $token) {
    event(id: $eventId) {
      viewerParticipant {
        confirmBookingOperations(ids: $bookingOperationIds) {
          inputError {
            message
          }
        }
      }
    }
  }
}
    `;

export function useConfirmBookingOperationsMutation() {
  return Urql.useMutation<Types.ConfirmBookingOperationsMutation, Types.ConfirmBookingOperationsMutationVariables>(Types.ConfirmBookingOperationsDocument);
};
export const CreateBookingDocument = gql`
    mutation CreateBooking($token: String, $locale: String!, $eventId: ID!, $operations: [BookingOperationCreateInput!]!, $companions: [CompanionBookingOperationCreateInput!]) {
  viewer(token: $token, language: $locale) {
    event(id: $eventId) {
      createBooking(
        booking: {bookingOperations: $operations, companions: $companions}
      ) {
        inputError {
          message
        }
        object {
          bookingNr
          participant {
            accessIdentifier
          }
        }
      }
    }
  }
}
    `;

export function useCreateBookingMutation() {
  return Urql.useMutation<Types.CreateBookingMutation, Types.CreateBookingMutationVariables>(Types.CreateBookingDocument);
};
export const CreateCancellationDocument = gql`
    mutation CreateCancellation($token: String, $eventId: ID!) {
  viewer(token: $token) {
    event(id: $eventId) {
      createCancellation(cancellation: {}) {
        inputError {
          message
        }
      }
    }
  }
}
    `;

export function useCreateCancellationMutation() {
  return Urql.useMutation<Types.CreateCancellationMutation, Types.CreateCancellationMutationVariables>(Types.CreateCancellationDocument);
};
export const CreateCommentDocument = gql`
    mutation CreateComment($token: String, $eventId: ID!, $text: String!) {
  viewer(token: $token) {
    event(id: $eventId) {
      createComment(comment: {text: $text}) {
        inputError {
          message
        }
      }
    }
  }
}
    `;

export function useCreateCommentMutation() {
  return Urql.useMutation<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>(Types.CreateCommentDocument);
};
export const CreateEventDocument = gql`
    mutation CreateEvent($token: String, $title: String!, $image: Resource, $eventTypeId: ID!, $categoryIds: [ID!], $description: String, $venueId: ID, $newVenue: VenueCreateInput, $onlineEventSetting: ExternalOnlineEventSettingInput, $operationDates: [OperationDateCreateInput!]!, $deadline: DateTime, $minParticipant: Int, $maxParticipant: Int, $remark: String) {
  viewer(token: $token) {
    createEvent(
      event: {title: $title, image: $image, eventTypeId: $eventTypeId, categoryIds: $categoryIds, description: $description, venueId: $venueId, newVenue: $newVenue, onlineEventSetting: $onlineEventSetting, operationDates: $operationDates, deadline: $deadline, minParticipant: $minParticipant, maxParticipant: $maxParticipant, remark: $remark}
    ) {
      inputError {
        message
      }
    }
  }
}
    `;

export function useCreateEventMutation() {
  return Urql.useMutation<Types.CreateEventMutation, Types.CreateEventMutationVariables>(Types.CreateEventDocument);
};
export const CreateUserDocument = gql`
    mutation CreateUser($user: UserCreateInput!) {
  viewer {
    user {
      createUser(user: $user) {
        inputError {
          message
        }
        success
      }
    }
  }
}
    `;

export function useCreateUserMutation() {
  return Urql.useMutation<Types.CreateUserMutation, Types.CreateUserMutationVariables>(Types.CreateUserDocument);
};
export const DeleteBookingDocument = gql`
    mutation DeleteBooking($token: String!, $eventId: ID!, $bookingId: ID!) {
  viewer(token: $token) {
    event(id: $eventId) {
      viewerParticipant {
        deleteBooking(id: $bookingId) {
          inputError {
            message
          }
        }
      }
    }
  }
}
    `;

export function useDeleteBookingMutation() {
  return Urql.useMutation<Types.DeleteBookingMutation, Types.DeleteBookingMutationVariables>(Types.DeleteBookingDocument);
};
export const DeleteBookingOperationsDocument = gql`
    mutation DeleteBookingOperations($token: String!, $eventId: ID!, $bookingOperationIds: [ID!]!) {
  viewer(token: $token) {
    event(id: $eventId) {
      viewerParticipant {
        deleteBookingOperations(ids: $bookingOperationIds) {
          inputError {
            message
          }
        }
      }
    }
  }
}
    `;

export function useDeleteBookingOperationsMutation() {
  return Urql.useMutation<Types.DeleteBookingOperationsMutation, Types.DeleteBookingOperationsMutationVariables>(Types.DeleteBookingOperationsDocument);
};
export const DeleteCommentDocument = gql`
    mutation DeleteComment($token: String, $eventId: ID!, $commentId: ID!) {
  viewer(token: $token) {
    event(id: $eventId) {
      deleteComment(id: $commentId) {
        inputError {
          message
        }
      }
    }
  }
}
    `;

export function useDeleteCommentMutation() {
  return Urql.useMutation<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>(Types.DeleteCommentDocument);
};
export const ParticipantLoginDocument = gql`
    mutation ParticipantLogin($accessIdentifier: String!) {
  authentication {
    createTokenByParticipant(participant: {accessIdentifier: $accessIdentifier}) {
      token
    }
  }
}
    `;

export function useParticipantLoginMutation() {
  return Urql.useMutation<Types.ParticipantLoginMutation, Types.ParticipantLoginMutationVariables>(Types.ParticipantLoginDocument);
};
export const RejectInviteeDocument = gql`
    mutation RejectInvitee($token: String!, $eventId: ID!) {
  viewer(token: $token) {
    event(id: $eventId) {
      viewerInvitee {
        reject {
          inputError {
            message
          }
          object {
            inviteeStatus
          }
        }
      }
    }
  }
}
    `;

export function useRejectInviteeMutation() {
  return Urql.useMutation<Types.RejectInviteeMutation, Types.RejectInviteeMutationVariables>(Types.RejectInviteeDocument);
};
export const RemoveGroupBookingParticipantDocument = gql`
    mutation RemoveGroupBookingParticipant($token: String!, $eventId: ID!, $groupBookingId: ID!, $participantId: ID!) {
  viewer(token: $token) {
    event(id: $eventId) {
      viewerParticipant {
        removeGroupBookingParticipant(
          groupBookingParticipant: {id: $groupBookingId, participantId: $participantId}
        ) {
          inputError {
            message
          }
        }
      }
    }
  }
}
    `;

export function useRemoveGroupBookingParticipantMutation() {
  return Urql.useMutation<Types.RemoveGroupBookingParticipantMutation, Types.RemoveGroupBookingParticipantMutationVariables>(Types.RemoveGroupBookingParticipantDocument);
};
export const RequestPasswordResetDocument = gql`
    mutation RequestPasswordReset($user: UserInput!) {
  viewer {
    user {
      requestPasswordReset(user: $user) {
        inputError {
          message
        }
        success
      }
    }
  }
}
    `;

export function useRequestPasswordResetMutation() {
  return Urql.useMutation<Types.RequestPasswordResetMutation, Types.RequestPasswordResetMutationVariables>(Types.RequestPasswordResetDocument);
};
export const ResendBookingConfirmationByParticipantEmailDocument = gql`
    mutation ResendBookingConfirmationByParticipantEmail($eventId: ID!, $email: String!) {
  viewer {
    event(id: $eventId) {
      resendBookingConfirmationByParticipantEmail(email: $email) {
        inputError {
          message
        }
        success
      }
    }
  }
}
    `;

export function useResendBookingConfirmationByParticipantEmailMutation() {
  return Urql.useMutation<Types.ResendBookingConfirmationByParticipantEmailMutation, Types.ResendBookingConfirmationByParticipantEmailMutationVariables>(Types.ResendBookingConfirmationByParticipantEmailDocument);
};
export const ResendBookingConfirmationDocument = gql`
    mutation ResendBookingConfirmation($token: String!, $eventId: ID!, $bookingId: ID!) {
  viewer(token: $token) {
    event(id: $eventId) {
      viewerParticipant {
        resendBookingConfirmation(id: $bookingId) {
          inputError {
            message
          }
        }
      }
    }
  }
}
    `;

export function useResendBookingConfirmationMutation() {
  return Urql.useMutation<Types.ResendBookingConfirmationMutation, Types.ResendBookingConfirmationMutationVariables>(Types.ResendBookingConfirmationDocument);
};
export const ResetPasswordDocument = gql`
    mutation ResetPassword($token: String!, $password: String!) {
  viewer {
    user {
      resetPassword(password: {token: $token, password: $password}) {
        inputError {
          message
        }
      }
    }
  }
}
    `;

export function useResetPasswordMutation() {
  return Urql.useMutation<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>(Types.ResetPasswordDocument);
};
export const SendEmailDocument = gql`
    mutation SendEmail($token: String, $eventId: ID!, $message: MessageInput!, $sender: MessageSenderInput) {
  viewer(token: $token) {
    event(id: $eventId) {
      sendEmailMessage(message: $message, sender: $sender) {
        inputError {
          message
        }
        success
      }
    }
  }
}
    `;

export function useSendEmailMutation() {
  return Urql.useMutation<Types.SendEmailMutation, Types.SendEmailMutationVariables>(Types.SendEmailDocument);
};
export const SendGlobalEmailDocument = gql`
    mutation SendGlobalEmail($token: String, $message: MessageInput!, $sender: MessageSenderInput) {
  viewer(token: $token) {
    sendEmailMessage(message: $message, sender: $sender) {
      inputError {
        message
      }
      success
    }
  }
}
    `;

export function useSendGlobalEmailMutation() {
  return Urql.useMutation<Types.SendGlobalEmailMutation, Types.SendGlobalEmailMutationVariables>(Types.SendGlobalEmailDocument);
};
export const SsoLoginDocument = gql`
    mutation SSOLogin($callbackUri: String!) {
  authentication {
    ssoUri: getSsoUri(callbackUri: $callbackUri)
  }
}
    `;

export function useSsoLoginMutation() {
  return Urql.useMutation<Types.SsoLoginMutation, Types.SsoLoginMutationVariables>(Types.SsoLoginDocument);
};
export const UpdateBookingDocument = gql`
    mutation UpdateBooking($token: String, $eventId: ID!, $bookingId: ID!, $operations: [BookingOperationCreateInput!], $companions: [CompanionBookingOperationCreateInput!]) {
  viewer(token: $token) {
    event(id: $eventId) {
      viewerParticipant {
        updateBooking(
          booking: {id: $bookingId, bookingOperations: $operations, companions: $companions}
        ) {
          inputError {
            message
          }
          object {
            bookingNr
            participant {
              accessIdentifier
            }
          }
        }
      }
    }
  }
}
    `;

export function useUpdateBookingMutation() {
  return Urql.useMutation<Types.UpdateBookingMutation, Types.UpdateBookingMutationVariables>(Types.UpdateBookingDocument);
};
export const UpdateGroupBookingDocument = gql`
    mutation UpdateGroupBooking($token: String!, $eventId: ID!, $groupBookingId: ID!, $description: String!, $public: Boolean!) {
  viewer(token: $token) {
    event(id: $eventId) {
      viewerParticipant {
        updateGroupBooking(
          groupBooking: {id: $groupBookingId, description: $description, public: $public}
        ) {
          inputError {
            message
          }
        }
      }
    }
  }
}
    `;

export function useUpdateGroupBookingMutation() {
  return Urql.useMutation<Types.UpdateGroupBookingMutation, Types.UpdateGroupBookingMutationVariables>(Types.UpdateGroupBookingDocument);
};
export const UpdateParticipantDocument = gql`
    mutation UpdateParticipant($token: String!, $eventId: ID!, $inputFieldValues: [InputFieldValueInput!]!, $representative: CompanionCreateInput, $public: Boolean, $bookingId: ID!, $bookingInputFieldValues: [InputFieldValueInput!]!) {
  viewer(token: $token) {
    event(id: $eventId) {
      viewerParticipant {
        updateParticipant(
          participant: {inputFieldValues: $inputFieldValues, public: $public, representative: $representative}
        ) {
          inputError {
            message
          }
        }
        updateBooking(
          booking: {id: $bookingId, inputFieldValues: $bookingInputFieldValues}
        ) {
          inputError {
            message
          }
        }
      }
    }
  }
}
    `;

export function useUpdateParticipantMutation() {
  return Urql.useMutation<Types.UpdateParticipantMutation, Types.UpdateParticipantMutationVariables>(Types.UpdateParticipantDocument);
};
export const UpdateUserDocument = gql`
    mutation UpdateUser($token: String!, $user: UserUpdateInput!) {
  viewer(token: $token) {
    updateUser(user: $user) {
      inputError {
        message
      }
    }
  }
}
    `;

export function useUpdateUserMutation() {
  return Urql.useMutation<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>(Types.UpdateUserDocument);
};
export const UsernamePasswordLoginDocument = gql`
    mutation UsernamePasswordLogin($username: String!, $password: String!) {
  authentication {
    createTokenByUsernamePassword(
      usernamePassword: {username: $username, password: $password}
    ) {
      token
    }
  }
}
    `;

export function useUsernamePasswordLoginMutation() {
  return Urql.useMutation<Types.UsernamePasswordLoginMutation, Types.UsernamePasswordLoginMutationVariables>(Types.UsernamePasswordLoginDocument);
};
export const AppDocument = gql`
    query App {
  app {
    authenticationMethods
    branding {
      ...Branding
    }
    eventCreation
    userRegistration
  }
}
    ${BrandingFragmentDoc}`;

export function useAppQuery(options?: Omit<Urql.UseQueryArgs<Types.AppQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.AppQuery, Types.AppQueryVariables>({ query: Types.AppDocument, ...options });
};
export const BookingsDocument = gql`
    query Bookings($token: String, $locale: String!, $eventKeyOrId: String!) {
  viewer(token: $token, language: $locale) {
    authenticationMethod
    event(key: $eventKeyOrId) {
      id
      key
      title
      authenticationMethods
      timezone {
        name
      }
      startDate
      endDate
      deadline
      capacity {
        available
        waitinglist
        bookable
      }
      onlineEventSettings {
        ...OnlineEventSettings
      }
      viewerRegistration {
        cancellation
        running
      }
      viewerParticipant {
        id
        bookings(statuses: [CONFIRMED, PENDING, REQUESTED]) {
          ...Booking
        }
        cancellation {
          id
        }
      }
      operations {
        ...Operation
        viewerBookable {
          maximum
        }
      }
      subEvents {
        id
        title
        startDate
        endDate
        onlineEventSettings {
          ...OnlineEventSettings
        }
        operations {
          ...Operation
          viewerBookable {
            maximum
          }
        }
      }
    }
  }
}
    ${OnlineEventSettingsFragmentDoc}
${BookingFragmentDoc}
${OperationFragmentDoc}`;

export function useBookingsQuery(options: Omit<Urql.UseQueryArgs<Types.BookingsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.BookingsQuery, Types.BookingsQueryVariables>({ query: Types.BookingsDocument, ...options });
};
export const CategoriesAndEventTypesDocument = gql`
    query CategoriesAndEventTypes($token: String, $locale: String!, $filterActiveOnly: Boolean = false, $filterAppLibraryOnly: Boolean!, $filterEventCreationOnly: Boolean!) {
  viewer(token: $token, language: $locale) {
    categories(filter: {filterAppLibraryOnly: true}) {
      id
      name
      image {
        thumbnail(width: 486, height: 300) {
          resource
        }
      }
    }
    eventTypes(
      filter: {filterAppLibraryOnly: $filterAppLibraryOnly, filterEventCreationOnly: $filterEventCreationOnly}
    ) {
      id
      key
      name
      categories(filter: {filterActiveOnly: $filterActiveOnly}) {
        id
        name
      }
    }
  }
}
    `;

export function useCategoriesAndEventTypesQuery(options: Omit<Urql.UseQueryArgs<Types.CategoriesAndEventTypesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.CategoriesAndEventTypesQuery, Types.CategoriesAndEventTypesQueryVariables>({ query: Types.CategoriesAndEventTypesDocument, ...options });
};
export const CommentsDocument = gql`
    query Comments($token: String, $locale: String, $eventKeyOrId: String!, $length: Int, $offset: Int) {
  viewer(token: $token, language: $locale) {
    event(key: $eventKeyOrId) {
      id
      comments(limit: {length: $length, offset: $offset}) {
        itemCount
        totalCount
        items {
          id
          firstName
          lastName
          userId
          thumbnail {
            thumbnail(width: 100, height: 100) {
              resource
            }
          }
          creationDate
          text
          deletable
        }
      }
    }
  }
}
    `;

export function useCommentsQuery(options: Omit<Urql.UseQueryArgs<Types.CommentsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.CommentsQuery, Types.CommentsQueryVariables>({ query: Types.CommentsDocument, ...options });
};
export const EventDatesDocument = gql`
    query EventDates($token: String, $locale: String!, $length: Int!, $search: String, $filterFromDate: DateTime!, $filterToDate: DateTime!, $filterCategoryIds: [ID!], $filterEventTypeIds: [ID!]) {
  viewer(token: $token, language: $locale) {
    dates: eventTeasers(
      limit: {length: $length}
      filter: {search: $search, filterFromDate: $filterFromDate, filterToDate: $filterToDate, filterCategoryIds: $filterCategoryIds, filterEventTypeIds: $filterEventTypeIds, filterAppLibraryOnly: true}
    ) {
      items {
        timezone {
          name
        }
        operations {
          operationDates {
            startDate
            endDate
          }
        }
      }
    }
  }
}
    `;

export function useEventDatesQuery(options: Omit<Urql.UseQueryArgs<Types.EventDatesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EventDatesQuery, Types.EventDatesQueryVariables>({ query: Types.EventDatesDocument, ...options });
};
export const EventDocument = gql`
    query Event($token: String, $locale: String!, $eventKeyOrId: String!) {
  viewer(token: $token, language: $locale) {
    event(key: $eventKeyOrId) {
      id
      key
      title
      authenticationMethods
      language {
        default
        available
      }
      timezone {
        name
      }
      startDate
      endDate
      deadline
      capacity {
        available
        waitinglist
        bookable
      }
      description
      image {
        small: thumbnail(width: 480, height: 320) {
          resource
        }
        medium: thumbnail(width: 960, height: 640) {
          resource
        }
        large: thumbnail(width: 1650, height: 1100) {
          resource
        }
      }
      wideImage: image {
        small: thumbnail(width: 600, height: 218) {
          resource
        }
        medium: thumbnail(width: 1200, height: 436) {
          resource
        }
        large: thumbnail(width: 2712, height: 986) {
          resource
        }
      }
      branding {
        ...Branding
      }
      layout
      payment {
        currency {
          name
        }
      }
      eventType {
        key
        name
        domain
        standaloneLibrary
        appLibrary
      }
      online
      onlineEventSettings {
        ...OnlineEventSettings
      }
      venue {
        name
        latitude
        longitude
      }
      lowestPrice
      viewerRegistration {
        running
        cancellation
        participantDefinition {
          publicParticipantOptIn
        }
      }
      viewerParticipant {
        id
        accessIdentifier
        firstName
        lastName
        displayName
        email
        cancellation {
          id
        }
      }
      viewerInvitee {
        id
        firstName
        lastName
        displayName
        inviteeStatus
        email
        phone
        language
        company
        jobTitle
        complianceProcess
        complianceFormDownloadUrl
        complianceFormUploadUrl
        respondable
        operation {
          id
          title
          venue {
            name
          }
          operationDates {
            startDate
            endDate
          }
          marketPrice
          priceBreakdown
        }
        companions {
          id
          displayName
        }
      }
      organizerUsers {
        id
        firstName
        lastName
        displayName
        thumbnail {
          thumbnail(width: 80, height: 80) {
            width
            height
            resource
          }
        }
      }
      organizerOrganizations {
        id
        name
        image {
          thumbnail(width: 80, height: 80) {
            width
            height
            resource
          }
        }
      }
      additionalFields {
        ...AdditionalFields
      }
      agendaFeature
      agenda {
        id
        title
        startDate
        endDate
        lead
        description
        image {
          ...Image
        }
        additionalFields {
          ...AdditionalFields
        }
        tags
        speakers {
          id
          firstName
          lastName
          displayName
          thumbnail {
            thumbnail(width: 400, height: 400) {
              resource
            }
          }
        }
        venue {
          name
        }
      }
      speakerFeature
      participantFeature
      viewerParticipantFeature
      commentFeature
      viewerCommentFeature
      operations {
        id
        title
        operationDates {
          startDate
          endDate
        }
        description
        online
        onlineEventSettings {
          ...OnlineEventSettings
        }
      }
      subEvents {
        id
        title
        startDate
        endDate
        description
        online
        onlineEventSettings {
          ...OnlineEventSettings
        }
        operations {
          id
          title
          operationDates {
            startDate
            endDate
          }
          description
          online
          onlineEventSettings {
            ...OnlineEventSettings
          }
          capacity {
            maximum
            available
          }
          totalBookingCounts {
            total
          }
        }
      }
    }
  }
}
    ${BrandingFragmentDoc}
${OnlineEventSettingsFragmentDoc}
${AdditionalFieldsFragmentDoc}
${ImageFragmentDoc}`;

export function useEventQuery(options: Omit<Urql.UseQueryArgs<Types.EventQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EventQuery, Types.EventQueryVariables>({ query: Types.EventDocument, ...options });
};
export const EventTeasersDocument = gql`
    query EventTeasers($token: String, $locale: String!, $length: Int, $offset: Int, $search: String, $filterFromDate: DateTime, $filterToDate: DateTime, $filterCategoryIds: [ID!], $filterEventTypeIds: [ID!], $filterAppLibraryOnly: Boolean, $filterPromotedOnly: Boolean) {
  viewer(token: $token, language: $locale) {
    eventTeasers(
      limit: {length: $length, offset: $offset}
      filter: {search: $search, filterFromDate: $filterFromDate, filterToDate: $filterToDate, filterCategoryIds: $filterCategoryIds, filterEventTypeIds: $filterEventTypeIds, filterAppLibraryOnly: $filterAppLibraryOnly, filterPromotedOnly: $filterPromotedOnly}
    ) {
      itemCount
      totalCount
      items {
        ...EventTeaser
      }
    }
  }
}
    ${EventTeaserFragmentDoc}`;

export function useEventTeasersQuery(options: Omit<Urql.UseQueryArgs<Types.EventTeasersQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EventTeasersQuery, Types.EventTeasersQueryVariables>({ query: Types.EventTeasersDocument, ...options });
};
export const EventTypeDocument = gql`
    query EventType($token: String, $locale: String!, $eventTypeKeyOrId: String!, $contentSectionKey: String, $categoriesFilterActiveOnly: Boolean = false, $eventTeasersLength: Int, $eventTeasersOffset: Int, $eventTeasersSearch: String, $eventTeasersFilterFromDate: DateTime, $eventTeasersFilterToDate: DateTime, $eventTeasersFilterCategoryIds: [ID!], $eventTeasersFilterPromotedOnly: Boolean) {
  viewer(token: $token, language: $locale) {
    eventType(key: $eventTypeKeyOrId) {
      id
      key
      name
      domain
      authenticationMethods
      standaloneLibrary
      eventCreation
      image {
        small: thumbnail(width: 600, height: 218) {
          resource
        }
        medium: thumbnail(width: 1200, height: 436) {
          resource
        }
        large: thumbnail(width: 2712, height: 986) {
          resource
        }
      }
      contentSection(key: $contentSectionKey) {
        id
        key
        title
        text
        additionalFields {
          ...AdditionalFields
        }
      }
      contentSections {
        id
        key
        title
      }
      categories(filter: {filterActiveOnly: $categoriesFilterActiveOnly}) {
        id
        name
      }
      eventTeasers(
        limit: {length: $eventTeasersLength, offset: $eventTeasersOffset}
        filter: {search: $eventTeasersSearch, filterFromDate: $eventTeasersFilterFromDate, filterToDate: $eventTeasersFilterToDate, filterCategoryIds: $eventTeasersFilterCategoryIds, filterPromotedOnly: $eventTeasersFilterPromotedOnly}
      ) {
        itemCount
        totalCount
        items {
          ...EventTeaser
        }
      }
      branding {
        ...Branding
      }
    }
  }
}
    ${AdditionalFieldsFragmentDoc}
${EventTeaserFragmentDoc}
${BrandingFragmentDoc}`;

export function useEventTypeQuery(options: Omit<Urql.UseQueryArgs<Types.EventTypeQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.EventTypeQuery, Types.EventTypeQueryVariables>({ query: Types.EventTypeDocument, ...options });
};
export const LoginEventTypesDocument = gql`
    query LoginEventTypes($locale: String!) {
  viewer(language: $locale) {
    eventTypes(filter: {filterPublicLibraryOnly: true}) {
      id
      key
      url
      name
      image {
        thumbnail(width: 712, height: 474) {
          resource
        }
      }
    }
  }
}
    `;

export function useLoginEventTypesQuery(options: Omit<Urql.UseQueryArgs<Types.LoginEventTypesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.LoginEventTypesQuery, Types.LoginEventTypesQueryVariables>({ query: Types.LoginEventTypesDocument, ...options });
};
export const ParticipantsDocument = gql`
    query Participants($token: String, $locale: String!, $eventKeyOrId: String!, $length: Int!, $offset: Int!) {
  viewer(token: $token, language: $locale) {
    event(key: $eventKeyOrId) {
      participants(limit: {length: $length, offset: $offset}) {
        itemCount
        totalCount
        items {
          id
          firstName
          lastName
          displayName
          thumbnail {
            thumbnail(width: 200, height: 200) {
              resource
            }
          }
          userId
        }
      }
    }
  }
}
    `;

export function useParticipantsQuery(options: Omit<Urql.UseQueryArgs<Types.ParticipantsQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.ParticipantsQuery, Types.ParticipantsQueryVariables>({ query: Types.ParticipantsDocument, ...options });
};
export const PaymentGatewayUriDocument = gql`
    query PaymentGatewayUri($eventKeyOrId: String!, $token: String, $locale: String!, $successCallbackUri: String!, $failCallbackUri: String!, $cancelCallbackUri: String!) {
  viewer(token: $token, language: $locale) {
    event(key: $eventKeyOrId) {
      viewerParticipant {
        id
        accessIdentifier
        bookings {
          id
          bookingNr
          bookingStatus
          payment {
            gatewayUri(
              successCallbackUri: $successCallbackUri
              failCallbackUri: $failCallbackUri
              cancelCallbackUri: $cancelCallbackUri
            )
          }
        }
      }
    }
  }
}
    `;

export function usePaymentGatewayUriQuery(options: Omit<Urql.UseQueryArgs<Types.PaymentGatewayUriQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.PaymentGatewayUriQuery, Types.PaymentGatewayUriQueryVariables>({ query: Types.PaymentGatewayUriDocument, ...options });
};
export const RegisterDocument = gql`
    query Register($eventKeyOrId: String!, $token: String, $locale: String!) {
  viewer(token: $token, language: $locale) {
    event(key: $eventKeyOrId) {
      id
      key
      title
      description
      authenticationMethods
      multiBookable
      timezone {
        name
      }
      startDate
      endDate
      online
      onlineEventSettings {
        ...OnlineEventSettings
      }
      payment {
        currency {
          name
        }
      }
      viewerRegistration {
        note
        participantDefinition {
          maximumCompanions
          inputFields {
            ...Input
          }
          companionInputFields {
            ...Input
          }
          publicParticipantOptIn
          representative
        }
        participantRequirements {
          name
          text
          file {
            title
            caption
            resource
          }
        }
        inputFields {
          ...Input
        }
      }
      viewerParticipant {
        id
        accessIdentifier
        bookings(statuses: [RESERVED, CONFIRMED, PENDING, REQUESTED, EXPIRED]) {
          ...Booking
        }
        companions {
          ...Companion
        }
      }
      operations {
        ...RegisterOperation
      }
      subEvents {
        id
        title
        description
        multiBookable
        onlineEventSettings {
          ...OnlineEventSettings
        }
        operations {
          ...RegisterOperation
        }
      }
    }
  }
}
    ${OnlineEventSettingsFragmentDoc}
${InputFragmentDoc}
${BookingFragmentDoc}
${CompanionFragmentDoc}
${RegisterOperationFragmentDoc}`;

export function useRegisterQuery(options: Omit<Urql.UseQueryArgs<Types.RegisterQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.RegisterQuery, Types.RegisterQueryVariables>({ query: Types.RegisterDocument, ...options });
};
export const SpeakersDocument = gql`
    query Speakers($token: String, $locale: String, $eventKeyOrId: String!, $length: Int, $offset: Int) {
  viewer(token: $token, language: $locale) {
    event(key: $eventKeyOrId) {
      id
      speakerCollection(limit: {length: $length, offset: $offset}) {
        itemCount
        totalCount
        items {
          id
          firstName
          lastName
          displayName
          jobTitle
          organization
          bio
          thumbnail {
            title
            thumbnail(width: 400, height: 400) {
              resource
            }
          }
          socialMedia {
            label
            url
            platform
          }
        }
      }
    }
  }
}
    `;

export function useSpeakersQuery(options: Omit<Urql.UseQueryArgs<Types.SpeakersQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.SpeakersQuery, Types.SpeakersQueryVariables>({ query: Types.SpeakersDocument, ...options });
};
export const UserDocument = gql`
    query User($token: String, $locale: String!, $id: ID, $length: Int, $offset: Int) {
  viewer(token: $token, language: $locale) {
    user(id: $id) {
      id
      firstName
      lastName
      displayName
      thumbnail {
        thumbnail(width: 300, height: 300) {
          resource
        }
      }
      image {
        thumbnail(width: 2600, height: 1000) {
          resource
        }
      }
      organizerEvents(limit: {length: $length, offset: $offset}) {
        totalCount
        itemCount
        items {
          ...EventTeaser
        }
      }
      participantEvents(limit: {length: $length, offset: $offset}) {
        totalCount
        itemCount
        items {
          ...EventTeaser
        }
      }
      ... on ViewerUser {
        reservationEvents(limit: {length: $length, offset: $offset}) {
          totalCount
          itemCount
          items {
            ...EventTeaser
          }
        }
      }
    }
  }
}
    ${EventTeaserFragmentDoc}`;

export function useUserQuery(options: Omit<Urql.UseQueryArgs<Types.UserQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.UserQuery, Types.UserQueryVariables>({ query: Types.UserDocument, ...options });
};
export const VenuesDocument = gql`
    query Venues($token: String, $locale: String!, $length: Int, $offset: Int, $search: String, $filterEventTypeId: ID) {
  viewer(token: $token, language: $locale) {
    venues(
      limit: {length: $length, offset: $offset}
      filter: {search: $search, filterEventTypeId: $filterEventTypeId}
    ) {
      items {
        id
        name
      }
    }
  }
}
    `;

export function useVenuesQuery(options: Omit<Urql.UseQueryArgs<Types.VenuesQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.VenuesQuery, Types.VenuesQueryVariables>({ query: Types.VenuesDocument, ...options });
};
export const ViewerUserDocument = gql`
    query ViewerUser($token: String, $locale: String!) {
  viewer(token: $token, language: $locale) {
    viewerUser {
      id
      username
      gender
      firstName
      lastName
      displayName
      email
      phone
      thumbnail {
        thumbnail(width: 300, height: 300) {
          resource
        }
      }
      image {
        thumbnail(width: 2600, height: 1000) {
          resource
        }
      }
      language
    }
  }
}
    `;

export function useViewerUserQuery(options: Omit<Urql.UseQueryArgs<Types.ViewerUserQueryVariables>, 'query'>) {
  return Urql.useQuery<Types.ViewerUserQuery, Types.ViewerUserQueryVariables>({ query: Types.ViewerUserDocument, ...options });
};