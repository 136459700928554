import { CompanionFragment } from '../../../../../../lib/graphql/generated/types'
import { CompanionValues } from '../../selection'
import inputFieldValuesToValues from '../inputFieldValuesToValues'

export const companionsToValues = (
    companions: CompanionFragment[]
): CompanionValues =>
    companions.reduce((acc: CompanionValues, companion) => {
        const companionValue = acc[companion.id] ?? {
            id: companion.id,
            displayName: companion.displayName,
            inputFieldValues: inputFieldValuesToValues(
                companion.inputFieldValues
            ),
            persisted: true,
        }

        return {
            ...acc,
            [companion.id]: companionValue,
        }
    }, {})

export default companionsToValues
