'use client'

import { Form, Formik } from 'formik'
import { FC, useCallback } from 'react'
import { createUser } from '../../../lib/actions'
import { phoneRegex } from '../../../lib/constants'
import { Gender } from '../../../lib/graphql/generated/types'
import {
    useNotification,
    useRouter,
    useTranslation,
    useYup,
} from '../../../lib/hooks'
import {
    Button,
    ButtonGroup,
    ButtonGroupLayout,
    ButtonTheme,
    Input,
    Select,
    SelectOption,
    Text,
} from '../../base'
import styles from '../login.module.css'

interface Values {
    email: string
    firstName: string
    gender: string
    lastName: string
    phone: string
}

interface Props {
    from?: string
}

const LoginCreationForm: FC<Props> = props => {
    const { t, locale } = useTranslation()
    const notification = useNotification()
    const router = useRouter()
    const yup = useYup()

    const goToLogin = useCallback(
        () =>
            router.replace('/login', {
                searchParams: props.from
                    ? new URLSearchParams({ from: props.from })
                    : undefined,
            }),
        [props.from, router]
    )

    const onSubmit = useCallback(
        async (values: Values) => {
            const result = await createUser({
                locale,
                user: {
                    ...values,
                    gender: values.gender as Gender,
                    language: locale,
                },
            })

            if (result?.error) {
                notification.alert(result.error)
            } else {
                goToLogin()
                notification.confirm(t('login:loginCreation.confirmation'))
            }
        },
        [goToLogin, locale, notification, t]
    )

    return (
        <>
            <Text element="h2" type="h2">
                {t('login:loginCreation.title')}
            </Text>
            <Formik<Values>
                initialValues={{
                    firstName: '',
                    lastName: '',
                    gender: '',
                    email: '',
                    phone: '',
                }}
                onSubmit={onSubmit}
                validationSchema={yup.object().shape({
                    firstName: yup.string().max(255).required(),
                    lastName: yup.string().max(255).required(),
                    gender: yup.string().required(),
                    email: yup.string().email().required(),
                    phone: yup.string().max(20).matches(phoneRegex),
                })}
            >
                {formik => (
                    <Form noValidate>
                        <div className={styles.fields}>
                            <Input
                                label={t('user:form.firstName')}
                                name="firstName"
                                required
                            />
                            <Input
                                label={t('user:form.lastName')}
                                name="lastName"
                                required
                            />
                            <Select
                                label={t('user:form.gender')}
                                name="gender"
                                placeholder={t('common:selectPlaceholder')}
                                required
                            >
                                <SelectOption value={Gender.Male}>
                                    {t('user:gender.male')}
                                </SelectOption>
                                <SelectOption value={Gender.Female}>
                                    {t('user:gender.female')}
                                </SelectOption>
                                <SelectOption value={Gender.Unspecified}>
                                    {t('user:gender.unspecified')}
                                </SelectOption>
                            </Select>
                            <Input
                                label={t('user:form.email')}
                                name="email"
                                type="email"
                                required
                            />
                            <Input label={t('user:form.phone')} name="phone" />
                        </div>
                        <ButtonGroup layout={ButtonGroupLayout.FullWidth}>
                            <Button loading={formik.isSubmitting} type="submit">
                                {t('common:send')}
                            </Button>
                            <Button
                                disabled={formik.isSubmitting}
                                onClick={goToLogin}
                                theme={ButtonTheme.Secondary}
                            >
                                {t('common:cancel')}
                            </Button>
                        </ButtonGroup>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default LoginCreationForm
