'use client'

import { Form, Formik } from 'formik'
import { debounce } from 'lodash'
import { useCookies } from 'next-client-cookies'
import { FC, useCallback, useMemo, useState } from 'react'
import { tokenKey } from '../../../../lib/constants'
import { useVenuesQuery } from '../../../../lib/graphql/generated/hooks'
import { useTranslation } from '../../../../lib/hooks'
import useYup from '../../../../lib/hooks/useYup'
import {
    Button,
    ButtonGroup,
    ButtonTheme,
    Input,
    Select,
    SelectOption,
    Switch,
    Textarea,
} from '../../../base'
import { StepProps } from '../../wizard'
import wizardStyles from '../../wizard.module.css'

export interface Values {
    isOnlineEvent: boolean
    onlineDescription: string
    onlineUrl: string
    venue: string
}

const VenueStep: FC<StepProps> = props => {
    const { t, locale } = useTranslation()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)
    const yup = useYup()
    const [searchQuery, setSearchQuery] = useState('')

    const [venuesQuery] = useVenuesQuery({
        variables: {
            token,
            locale,
            offset: 0,
            length: 10,
            filterEventTypeId: props.values.eventType,
            search: searchQuery,
        },
    })

    const setSearchQueryDebounced = useMemo(
        () => debounce((query: string) => setSearchQuery(query), 200),
        []
    )

    const onSkip = useCallback(() => {
        props.mergeValues.call(undefined, {
            venue: '',
            isOnlineEvent: false,
            onlineUrl: '',
            onlineDescription: '',
        })

        props.nextStep.call(undefined)
    }, [props.mergeValues, props.nextStep])

    const onSubmit = useCallback(
        async (values: Values) => {
            props.mergeValues.call(undefined, values)
            props.nextStep.call(undefined)
        },
        [props.mergeValues, props.nextStep]
    )

    return (
        <Formik<Values>
            initialValues={{
                venue: props.values.venue ?? '',
                isOnlineEvent: props.values.isOnlineEvent ?? false,
                onlineUrl: props.values.onlineUrl ?? '',
                onlineDescription: props.values.onlineDescription ?? '',
            }}
            onSubmit={onSubmit}
            validationSchema={yup.object().shape({
                venue: yup.string().max(255),
                onlineUrl: yup
                    .string()
                    .url()
                    .when('isOnlineEvent', ([isOnlineEvent], schema) =>
                        isOnlineEvent ? schema.url().required() : schema
                    ),
                onlineDescription: yup.string().max(255),
            })}
            enableReinitialize
            validateOnMount
        >
            {formik => (
                <Form className={wizardStyles.form} noValidate>
                    <div className={wizardStyles.fields}>
                        <div className={wizardStyles.colspan}>
                            <Select
                                label={t('wizard:form.venue')}
                                loading={venuesQuery.fetching}
                                name="venue"
                                onInputChange={setSearchQueryDebounced}
                                placeholder={t('common:typeToSearch')}
                                creatable
                            >
                                {venuesQuery?.data?.viewer?.venues?.items?.map(
                                    venue => (
                                        <SelectOption
                                            key={venue.id}
                                            value={venue.id}
                                        >
                                            {venue.name}
                                        </SelectOption>
                                    )
                                )}
                            </Select>
                        </div>
                        <Switch
                            className={wizardStyles.colspan}
                            name="isOnlineEvent"
                        >
                            {t('wizard:form.onlineEvent')}
                        </Switch>
                        {formik.values.isOnlineEvent && (
                            <>
                                <Input
                                    className={wizardStyles.colspan}
                                    label={t('wizard:form.onlineUrl.label')}
                                    name="onlineUrl"
                                    placeholder={t(
                                        'wizard:form.onlineUrl.placeholder'
                                    )}
                                    required
                                />
                                <Textarea
                                    className={wizardStyles.colspan}
                                    label={t(
                                        'wizard:form.onlineDescription.label'
                                    )}
                                    name="onlineDescription"
                                    placeholder={t(
                                        'wizard:form.onlineDescription.placeholder'
                                    )}
                                />
                            </>
                        )}
                    </div>
                    <ButtonGroup className={wizardStyles.actions}>
                        <Button disabled={!formik.isValid} type="submit">
                            {t('common:continue')}
                        </Button>
                        <Button
                            onClick={onSkip}
                            theme={ButtonTheme.Transparent}
                        >
                            {t('wizard:skip')}
                        </Button>
                    </ButtonGroup>
                </Form>
            )}
        </Formik>
    )
}

export default VenueStep
