'use client'

import { FC } from 'react'
import {
    InputFragment,
    OperationFragment,
} from '../../../../../lib/graphql/generated/types'
import { RegisterQueryEvent } from '../../../../../lib/types'
import { FormattedHtml, Text } from '../../../../base'
import BookingOperations from '../bookingOperations'
import styles from './bookingSection.module.css'

interface Props {
    companionInputFields: InputFragment[]
    event: RegisterQueryEvent
    eventKey: string
    section:
        | RegisterQueryEvent
        | NonNullable<RegisterQueryEvent['subEvents']>[number]
}

const BookingSection: FC<Props> = props => {
    const multiBookable = props.section.multiBookable || false

    if (!props.section.operations?.length) {
        return
    }

    return (
        <div
            id={
                props.section.__typename === 'SubEvent'
                    ? props.section.id
                    : undefined
            }
        >
            <Text className={styles.title} element="h2" type="h5" noMargin>
                {props.section.title}
            </Text>
            {props.section.__typename !== 'Event' &&
                props.section.description && (
                    <Text className={styles.description} element="div">
                        <FormattedHtml html={props.section.description} />
                    </Text>
                )}
            <div className={styles.operations}>
                {props.section.operations.map(operation => (
                    <BookingOperations
                        key={operation.id}
                        companionInputFields={props.companionInputFields}
                        event={props.event}
                        multiBookable={multiBookable}
                        operation={operation}
                        operations={
                            props.section.operations as OperationFragment[]
                        }
                    />
                ))}
            </div>
        </div>
    )
}

export default BookingSection
