'use client'

import { FC } from 'react'
import { DateFormat, Flyout, FormattedDate, Icon, Text } from '../../../base'
import styles from './eventDatesFlyout.module.css'

interface Props {
    endDate?: any
    eventKey: string
    operations: Array<{
        id: string
        operationDates: Array<{
            endDate?: any
            startDate: any
        }>
        title: string
    }>
    startDate: any
    timezone?: string
}

const EventDatesFlyout: FC<Props> = props => (
    <Flyout
        toggle={<Icon name="dots-three" />}
        toggleClassName={styles.toggle}
        openOnHover
    >
        <div className={styles.content}>
            {props.operations.length ? (
                props.operations.map(operation => {
                    return (
                        <div key={operation.id}>
                            <Text
                                className={styles.title}
                                element="h5"
                                type="h5"
                            >
                                {operation.title}
                            </Text>
                            <ul className={styles.dates}>
                                {operation.operationDates.length ? (
                                    operation.operationDates.map((date, i) => (
                                        <li key={i}>
                                            <Text element="span" type="small">
                                                <FormattedDate
                                                    endDate={date.endDate}
                                                    format={DateFormat.Short}
                                                    startDate={date.startDate}
                                                    timezone={props.timezone}
                                                    withTime
                                                />
                                            </Text>
                                        </li>
                                    ))
                                ) : (
                                    <li>
                                        <Text element="span" type="small">
                                            <FormattedDate
                                                endDate={props.endDate}
                                                format={DateFormat.Short}
                                                startDate={props.startDate}
                                                timezone={props.timezone}
                                                withTime
                                            />
                                        </Text>
                                    </li>
                                )}
                            </ul>
                        </div>
                    )
                })
            ) : (
                <ul className={styles.dates}>
                    <li>
                        <Text element="span" type="small">
                            <FormattedDate
                                endDate={props.endDate}
                                format={DateFormat.Short}
                                startDate={props.startDate}
                                timezone={props.timezone}
                                withTime
                            />
                        </Text>
                    </li>
                </ul>
            )}
        </div>
    </Flyout>
)

export default EventDatesFlyout
