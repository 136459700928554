'use client'

import { FC, useCallback, useState } from 'react'
import {
    SocialMediaPlatform,
    SpeakersQuery,
} from '../../../../lib/graphql/generated/types'
import {
    Avatar,
    Button,
    ButtonTheme,
    Dialog,
    FormattedHtml,
    IconProps,
    Text,
} from '../../../base'
import styles from './speaker.module.css'

export interface Props {
    speaker: NonNullable<
        NonNullable<
            NonNullable<SpeakersQuery['viewer']>['event']
        >['speakerCollection']
    >['items'][number]
    width: number
}

const Speaker: FC<Props> = (props: Props) => {
    const [dialogOpen, setDialogOpen] = useState(false)

    const openDialog = useCallback(() => setDialogOpen(true), [])

    const closeDialog = useCallback(() => setDialogOpen(false), [])

    if (!props.speaker.displayName) {
        return null
    }

    const name = props.speaker.displayName

    const social = props.speaker.socialMedia.length ? (
        <div className={styles.social}>
            {props.speaker.socialMedia.map(item => {
                if (item.platform === SocialMediaPlatform.X) {
                    return (
                        <Button
                            key={item.platform}
                            href={item.url}
                            theme={ButtonTheme.Transparent}
                        >
                            𝕏
                        </Button>
                    )
                }

                let iconName: IconProps['name'] | undefined

                switch (item.platform) {
                    case SocialMediaPlatform.Instagram:
                        iconName = 'instagram-logo'
                        break
                    case SocialMediaPlatform.Linkedin:
                        iconName = 'linkedin-logo'
                        break
                }

                if (iconName) {
                    return (
                        <Button
                            key={item.platform}
                            href={item.url}
                            iconName={iconName}
                            iconSize={2}
                            theme={ButtonTheme.Transparent}
                        />
                    )
                }
            })}
        </div>
    ) : null

    const job =
        props.speaker.jobTitle || props.speaker.organization ? (
            <div>
                {props.speaker.jobTitle}
                {props.speaker.jobTitle && props.speaker.organization && ' - '}
                {props.speaker.organization}
            </div>
        ) : null

    return (
        <>
            <div className={styles.speaker}>
                <Avatar
                    firstName={props.speaker.firstName}
                    image={props.speaker.thumbnail?.thumbnail?.resource}
                    lastName={props.speaker.lastName}
                    onClick={openDialog}
                    size={props.width}
                />
                {social}
                <div className={styles.details}>
                    <Text element="div" weight="600">
                        {name}
                    </Text>
                    {job}
                </div>
            </div>
            <Dialog
                className={styles.speaker}
                onClose={closeDialog}
                open={dialogOpen}
                title={name}
                small
            >
                <Avatar
                    firstName={props.speaker.firstName}
                    image={props.speaker.thumbnail?.thumbnail?.resource}
                    lastName={props.speaker.lastName}
                    onClick={openDialog}
                    size={props.width}
                />
                {social}
                {job}
                {props.speaker.bio && (
                    <FormattedHtml html={props.speaker.bio} />
                )}
            </Dialog>
        </>
    )
}

export default Speaker
