/* istanbul ignore file */
'use client'

import { Form, Formik } from 'formik'
import { FC } from 'react'
import { useYup } from '../../lib/hooks'
import { Button, ButtonGroup, Card, DatePickerType, Input } from '../client'
import styles from './examples.module.css'
import useOnSubmit from './useOnSubmit'

const InputExample: FC = () => {
    const yup = useYup()
    const { onSubmit } = useOnSubmit()

    return (
        <Card title="Input">
            <Formik
                initialValues={{
                    input: '',
                    number: undefined,
                    password: '',
                    date: undefined,
                    time: undefined,
                    dateTime: undefined,
                    search: '',
                }}
                onSubmit={onSubmit}
                validationSchema={yup.object().shape({
                    input: yup.string().max(255).required(),
                    number: yup.number(),
                    password: yup.string(),
                    date: yup.date().required(),
                    time: yup.date(),
                    dateTime: yup.date(),
                    search: yup.string(),
                })}
            >
                {formik => (
                    <Form className={styles.flexCol} noValidate>
                        <Input
                            help="Help text (max 255 characters)"
                            label="Label"
                            name="input"
                            placeholder="Placeholder"
                            required
                        />
                        <Input
                            label="Number"
                            name="number"
                            placeholder="0123456789"
                            type="number"
                        />
                        <Input
                            label="Password"
                            name="password"
                            placeholder="It's a secret"
                            type="password"
                        />
                        <Input label="Date" name="date" type="date" required />
                        <Input
                            datePickerType={DatePickerType.Time}
                            label="Time"
                            name="time"
                            type="date"
                        />
                        <Input
                            datePickerType={DatePickerType.DateTime}
                            label="Date and time"
                            name="dateTime"
                            type="date"
                        />
                        <Input label="Search" name="search" type="search" />
                        <ButtonGroup>
                            <Button loading={formik.isSubmitting} type="submit">
                                Submit
                            </Button>
                        </ButtonGroup>
                    </Form>
                )}
            </Formik>
        </Card>
    )
}

export default InputExample
