'use client'

import clsx from 'clsx'
import { upperFirst } from 'lodash'
import { useCookies } from 'next-client-cookies'
import { FC, useCallback, useState } from 'react'
import { tokenKey } from '../../../../lib/constants'
import { useDeleteCommentMutation } from '../../../../lib/graphql/generated/hooks'
import { CommentsQuery } from '../../../../lib/graphql/generated/types'
import { useTranslation, useViewerUser } from '../../../../lib/hooks'
import { dayjsFrom } from '../../../../lib/utils'
import {
    Avatar,
    Button,
    ButtonGroup,
    ButtonTheme,
    Collapsible,
    Dialog,
    Text,
} from '../../../base'
import styles from './comment.module.css'

export interface Props {
    comment: NonNullable<
        NonNullable<NonNullable<CommentsQuery['viewer']>['event']>['comments']
    >['items'][number]
    eventId: string
}

const Comment: FC<Props> = props => {
    const { t } = useTranslation()
    const viewerUser = useViewerUser()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)
    const [, deleteComment] = useDeleteCommentMutation()
    const creationDate = dayjsFrom(props.comment.creationDate)
    const [collapsed, setCollapsed] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)

    const openDialog = useCallback(() => setDialogOpen(true), [])

    const closeDialog = useCallback(() => setDialogOpen(false), [])

    const onDelete = useCallback(async () => {
        if (!token || !props.comment) {
            return
        }

        closeDialog()
        setTimeout(() => setCollapsed(true), 300)

        try {
            const { data, error } = await deleteComment(
                {
                    token,
                    eventId: props.eventId,
                    commentId: props.comment.id,
                },
                { additionalTypenames: ['CommentCollection'] }
            )

            if (error) {
                throw error
            }

            if (data?.viewer?.event?.deleteComment?.inputError) {
                throw Error(data.viewer.event.deleteComment.inputError.message)
            }
        } catch (e) {
            console.error(e)
        }
    }, [closeDialog, deleteComment, props.comment, props.eventId, token])

    return (
        <>
            <Collapsible
                className={styles.body}
                collapsed={collapsed}
                containerClassName={clsx(
                    styles.root,
                    collapsed && styles.collapsed
                )}
            >
                <Avatar
                    className={styles.avatar}
                    firstName={props.comment.firstName}
                    href={
                        viewerUser && props.comment.userId
                            ? `/profile/${props.comment.userId}`
                            : undefined
                    }
                    image={props.comment.thumbnail?.thumbnail?.resource}
                    lastName={props.comment.lastName}
                    size={50}
                />
                <div className={styles.comment}>
                    <Text element="div" type="small" weight="500">
                        {props.comment.firstName} {props.comment.lastName}
                    </Text>
                    <Text className={styles.text} element="div">
                        {props.comment.text}
                    </Text>
                    <div className={styles.footer}>
                        {creationDate && (
                            <Text element="span" type="small" noWrap>
                                {upperFirst(creationDate.fromNow())}
                            </Text>
                        )}
                        {props.comment.deletable && (
                            <>
                                <Text
                                    className={styles.separator}
                                    element="span"
                                    type="small"
                                >
                                    |
                                </Text>
                                <Button
                                    className={styles.button}
                                    onClick={openDialog}
                                    theme={ButtonTheme.Transparent}
                                >
                                    {t('common:delete')}
                                </Button>
                            </>
                        )}
                    </div>
                </div>
                <Dialog onClose={closeDialog} open={dialogOpen} small>
                    <Text>{t('event:comments.deletePrompt')}</Text>
                    <ButtonGroup>
                        <Button onClick={onDelete}>{t('common:delete')}</Button>
                        <Button
                            onClick={closeDialog}
                            theme={ButtonTheme.Cancel}
                        >
                            {t('common:cancel')}
                        </Button>
                    </ButtonGroup>
                </Dialog>
            </Collapsible>
        </>
    )
}

export default Comment
