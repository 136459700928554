import clsx from 'clsx'
import { FC } from 'react'
import { BookingFragment } from '../../../lib/graphql/generated/types'
import FormattedInputFieldValue from '../formattedInputFieldValue'
import styles from './representativeSummary.module.css'

interface Props {
    className?: string
    representative: BookingFragment['participant']['representative']
}

const RepresentativeSummary: FC<Props> = props => {
    return (
        props.representative && (
            <div className={clsx(styles.root, props.className)}>
                {props.representative.inputFieldValues.map(inputFieldValue => (
                    <FormattedInputFieldValue
                        key={inputFieldValue.inputField.identifier}
                        inputFieldValue={inputFieldValue}
                    />
                ))}
            </div>
        )
    )
}

export default RepresentativeSummary
