'use client'

import { useFormikContext } from 'formik'
import { debounce } from 'lodash'
import { FC, useEffect, useMemo, useRef } from 'react'
import {
    useNotification,
    useRouter,
    useTranslation,
} from '../../../../../lib/hooks'
import { RegisterQueryEvent } from '../../../../../lib/types'
import { operationToValue } from '../utils'

interface Props {
    sections: Array<
        | RegisterQueryEvent
        | NonNullable<RegisterQueryEvent['subEvents']>[number]
    >
}

const Preselector: FC<Props> = props => {
    const { t } = useTranslation()
    const notification = useNotification()
    const router = useRouter()
    const formik = useFormikContext()
    const paramsChecked = useRef(false)

    const scrollToId = useMemo(
        () =>
            debounce((id: string) => {
                const element = document.getElementById(id)

                if (element) {
                    element.scrollIntoView()
                }
            }, 200),
        []
    )

    useEffect(() => {
        if (paramsChecked.current || !props.sections.length) {
            return
        }

        paramsChecked.current = true

        const sectionId = router.searchParams.get('section')
        const operationId = router.searchParams.get('operation')
        const groupBookingId = router.searchParams.get('groupBooking')

        const operationInputFieldValues = (id: string) => {
            for (const section of props.sections) {
                const operation = section.operations?.find(o => o.id === id)

                if (operation) {
                    return operationToValue(operation).inputFieldValues
                }
            }

            return {}
        }

        if (typeof sectionId === 'string') {
            scrollToId(sectionId)
        } else if (typeof operationId === 'string') {
            formik
                .setFieldValue(operationId, [
                    {
                        inputFieldValues:
                            operationInputFieldValues(operationId),
                    },
                ])
                .catch(console.error)

            scrollToId(operationId)
        } else if (typeof groupBookingId === 'string') {
            let groupBookingOperation
            let groupBooking

            for (const section of props.sections) {
                if (!section.operations) {
                    continue
                }

                for (const operation of section.operations) {
                    if (groupBooking) {
                        break
                    }

                    if (!operation.groupBookings?.items) {
                        continue
                    }

                    for (const item of operation.groupBookings.items) {
                        if (
                            item?.__typename === 'GroupBooking' &&
                            item.id === groupBookingId
                        ) {
                            groupBookingOperation = operation
                            groupBooking = item
                            break
                        }
                    }

                    if (!groupBookingOperation || !groupBooking) {
                        continue
                    }

                    if (
                        !groupBookingOperation.groupBookingDefinition
                            ?.maximumGroupParticipants ||
                        groupBooking.participantCount <
                            groupBookingOperation.groupBookingDefinition
                                .maximumGroupParticipants
                    ) {
                        formik
                            .setFieldValue(groupBookingOperation.id, [
                                {
                                    inputFieldValues: operationInputFieldValues(
                                        groupBookingOperation.id
                                    ),
                                    groupBookingId,
                                },
                            ])
                            .catch(console.error)

                        scrollToId(groupBookingOperation.id)
                    } else {
                        notification.alert(t('groupBooking:fullyBooked'), {
                            modal: true,
                        })
                    }
                }
            }
        }

        const params = new URLSearchParams(router.searchParams)
        params.delete('section')
        params.delete('operation')
        params.delete('groupBooking')

        router.replaceSearchParams(params)
    }, [formik, notification, props.sections, router, scrollToId, t])

    return null
}

export default Preselector
