'use client'

import { useFormikContext } from 'formik'
import { ChangeEvent, FC, useCallback } from 'react'
import { Button, ButtonTheme, Checkbox, Icon } from '../../../base'
import {
    CompanionValue,
    OperationValues,
    Values,
} from '../../../event/registration/selection/selection'
import styles from './companionInputGroupItem.module.css'

interface Props {
    checked: boolean
    companion: CompanionValue
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
    operationId: string
}

const CompanionInputGroupItem: FC<Props> = props => {
    const formik = useFormikContext<Values>()
    const name = `operations.${props.operationId}[0].companionId`
    const CheckboxOrRadio = Checkbox // props.multiBookable ? Checkbox : Radio

    const removeCompanion = useCallback(() => {
        const { [props.companion.id]: removed, ...companions } =
            formik.values.companions
        const operations = Object.entries(formik.values.operations).reduce(
            (acc, [id, value]) => {
                acc[id] = value.filter(
                    _value => _value.companionId !== props.companion.id
                )
                return acc
            },
            {} as OperationValues
        )

        formik.setFieldValue('companions', companions).catch(console.error)
        formik.setFieldValue('operations', operations).catch(console.error)
    }, [formik, props.companion.id])

    return (
        <div className={styles.item}>
            <CheckboxOrRadio
                key={props.companion.id}
                checked={props.checked}
                disabled={formik.isSubmitting}
                name={name}
                onChange={props.onChange}
                value={props.companion.id}
            >
                {props.companion.displayName}
            </CheckboxOrRadio>
            <Button onClick={removeCompanion} theme={ButtonTheme.Transparent}>
                <Icon className={styles.icon} name="x" />
            </Button>
        </div>
    )
}

export default CompanionInputGroupItem
