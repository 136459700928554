/* istanbul ignore file */
'use server'

import {
    ResetPasswordMutation,
    ResetPasswordMutationVariables,
} from '../graphql/generated/types'
import getGraphqlClient from '../graphql/getGraphqlClient'
import resetPasswordMutation from '../graphql/mutations/resetPasswordMutation.graphql'
import { getTranslation } from '../i18n'
import { ServerActionResult } from '../types'

interface Args extends ResetPasswordMutationVariables {
    locale: string
}

const resetPassword = async ({
    locale,
    ...variables
}: Args): Promise<ServerActionResult> => {
    try {
        const { data, error } = await getGraphqlClient()
            .mutation<
                ResetPasswordMutation,
                ResetPasswordMutationVariables
            >(resetPasswordMutation, variables)
            .toPromise()

        if (error) {
            throw error
        }
        if (error) {
            throw error
        } else if (data?.viewer?.user?.resetPassword?.inputError) {
            throw Error(data.viewer.user.resetPassword.inputError.message)
        }
    } catch (e) {
        console.error(e)
        const { t } = await getTranslation(locale)

        return {
            error: t('common:error.resetPassword'),
        }
    }
}

export default resetPassword
