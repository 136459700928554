import {
    InputFragment,
    InputValueFragment,
} from '../../../../../../lib/graphql/generated/types'
import { InputFieldValueValues } from '../../../selection/selection'
import {
    decodeValueFromInputField,
    decodeValueFromInputFieldValue,
} from '../../../utils'

export const inputFieldsToValues = (
    inputFields: InputFragment[],
    inputFieldsValues: InputValueFragment[]
): InputFieldValueValues =>
    inputFields.reduce((acc, inputField) => {
        const inputFieldValue = inputFieldsValues.find(
            f => f.inputField.identifier === inputField.identifier
        )

        const value = inputFieldValue
            ? decodeValueFromInputFieldValue(inputFieldValue)
            : decodeValueFromInputField(inputField)

        return {
            ...acc,
            [inputField.identifier]: value,
        }
    }, {})
export default inputFieldsToValues
