'use client'

import { FC } from 'react'
import {
    BookingFragment,
    BookingStatus,
} from '../../../../lib/graphql/generated/types'
import { useTranslation } from '../../../../lib/hooks'
import { Button, ButtonTheme } from '../../../base'
import FormattedInputFieldValue from '../../formattedInputFieldValue'
import { GroupBookingUpdateDialog } from '../../groupBooking'
import styles from './bookingSummaryBookingOperation.module.css'

interface Props extends Pick<BookingFragment, 'bookingStatus'> {
    bookingOperation: BookingFragment['groupedBookingOperations'][number]['bookingOperations'][number]
    eventId: string
    groupBookingDefinition?: BookingFragment['groupedBookingOperations'][number]['operation']['groupBookingDefinition']
    groupedCompanions: boolean
    loading?: boolean
    participantId?: string
}

const BookingSummaryBookingOperation: FC<Props> = props => {
    const { t } = useTranslation()
    const { companion } = props.bookingOperation
    const { groupBooking } = props.bookingOperation

    if (
        !companion &&
        !groupBooking &&
        !props.bookingOperation.inputFieldValues.length
    ) {
        return
    }

    const canUpdateGroupBooking = Boolean(
        props.bookingStatus === BookingStatus.Confirmed &&
            groupBooking?.primaryParticipant.id &&
            props.participantId === groupBooking?.primaryParticipant.id
    )

    return (
        <div className={styles.root}>
            {!props.groupedCompanions && companion && (
                <div className={styles.companion}>
                    <strong>{t('companion:companion')}</strong>
                    <div>{companion.displayName}</div>
                </div>
            )}
            {groupBooking && (
                <div className={styles.groupBooking}>
                    <div>
                        <strong>{t('groupBooking:group')}</strong>
                        <div>{groupBooking.name}</div>
                    </div>
                    {props.groupBookingDefinition && canUpdateGroupBooking && (
                        <GroupBookingUpdateDialog
                            eventId={props.eventId}
                            groupBooking={groupBooking}
                            loading={props.loading}
                            maxParticipants={
                                props.groupBookingDefinition
                                    .maximumGroupParticipants || undefined
                            }
                        >
                            {open => (
                                <Button
                                    className={styles.button}
                                    iconName="pencil"
                                    onClick={open}
                                    theme={ButtonTheme.Transparent}
                                >
                                    {t('common:edit')}
                                </Button>
                            )}
                        </GroupBookingUpdateDialog>
                    )}
                </div>
            )}
            {props.bookingOperation.inputFieldValues.map(inputFieldValue => (
                <FormattedInputFieldValue
                    key={inputFieldValue.inputField.identifier}
                    inputFieldValue={inputFieldValue}
                />
            ))}
        </div>
    )
}

export default BookingSummaryBookingOperation
