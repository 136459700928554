'use client'

import { Form, Formik } from 'formik'
import { FC, useCallback } from 'react'
import { useTranslation, useYup } from '../../../../lib/hooks'
import { Button, ButtonGroup, Input } from '../../../base'
import { StepProps } from '../../wizard'
import wizardStyles from '../../wizard.module.css'

export interface Values {
    title: string
}

const TitleStep: FC<StepProps> = props => {
    const { t } = useTranslation()
    const yup = useYup()

    const onSubmit = useCallback(
        async (values: Values) => {
            props.mergeValues.call(undefined, values)
            props.nextStep.call(undefined)
        },
        [props.mergeValues, props.nextStep]
    )

    return (
        <Formik<Values>
            initialValues={{
                title: props.values.title ?? '',
            }}
            onSubmit={onSubmit}
            validationSchema={yup.object().shape({
                title: yup.string().max(255).required(),
            })}
            enableReinitialize
            validateOnMount
        >
            {formik => (
                <Form className={wizardStyles.form} noValidate>
                    <div className={wizardStyles.fields}>
                        <Input
                            className={wizardStyles.colspan}
                            label={t('wizard:form.title.label')}
                            name="title"
                            placeholder={t('wizard:form.title.placeholder')}
                            required
                        />
                    </div>
                    <ButtonGroup className={wizardStyles.actions}>
                        <Button disabled={!formik.isValid} type="submit">
                            {t('common:continue')}
                        </Button>
                    </ButtonGroup>
                </Form>
            )}
        </Formik>
    )
}

export default TitleStep
