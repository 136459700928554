import { FC } from 'react'
import Container from '../../base/container'
import FormattedDescription, {
    FormattedDescriptionProps,
} from '../formattedDescription'
import EventDetails, { EventDetailsProps } from './eventDetails'
import styles from './eventInformation.module.css'
import Organizers, { OrganizersProps } from './organizers'

type Props = Omit<FormattedDescriptionProps, 'className'> &
    OrganizersProps &
    EventDetailsProps

const EventInformation: FC<Props> = props => (
    <Container className={styles.root}>
        <div className={styles.anchor} id="information" />
        <FormattedDescription
            additionalFields={props.additionalFields}
            className={styles.description}
            description={props.description}
        />
        <div className={styles.sidebar}>
            <EventDetails
                capacity={props.capacity}
                endDate={props.endDate}
                lowestPrice={props.lowestPrice}
                online={props.online}
                operations={props.operations}
                payment={props.payment}
                startDate={props.startDate}
                timezone={props.timezone}
                venue={props.venue}
            />
            <Organizers
                eventId={props.eventId}
                organizations={props.organizations}
                users={props.users}
                viewerParticipant={props.viewerParticipant}
            />
        </div>
    </Container>
)

export default EventInformation
