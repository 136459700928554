'use client'

import { useFormikContext } from 'formik'
import { ChangeEvent, FC, useCallback, useState } from 'react'
import { InputFragment } from '../../../../../lib/graphql/generated/types'
import { useTranslation } from '../../../../../lib/hooks'
import { Checkbox, Text } from '../../../../base'
import Collapsible from '../../../../base/collapsible'
import InputField from '../../inputField'
import { Values } from '../details'
import styles from './detailsRepresentative.module.css'

interface Props {
    inputFields: InputFragment[]
}

const DetailsRepresentative: FC<Props> = props => {
    const { t } = useTranslation()
    const formik = useFormikContext<Values>()
    const [checked, setChecked] = useState(!formik.values.representativeEnabled)

    const onChange = useCallback(
        async (e: ChangeEvent<HTMLInputElement>) => {
            const value = e.currentTarget.checked
            setChecked(value)
            formik
                .setFieldValue('representativeEnabled', !value)
                .catch(console.error)
        },

        [formik]
    )
    return (
        <div className={styles.root}>
            <Text className={styles.title} element="h2" type="h4">
                {t('registration:information.representative.title')}
            </Text>
            <Collapsible
                className={styles.collapsible}
                collapsed={checked}
                toggle={
                    <Checkbox
                        checked={checked}
                        disabled={formik.isSubmitting}
                        name="representativeEnabled"
                        onChange={onChange}
                    >
                        {t(
                            'registration:information.representative.contactSelection'
                        )}
                    </Checkbox>
                }
            >
                {props.inputFields.map(inputField => {
                    return (
                        <InputField
                            key={inputField.identifier}
                            inputField={inputField}
                            name={`representative.${inputField.identifier}`}
                        />
                    )
                })}
            </Collapsible>
        </div>
    )
}

export default DetailsRepresentative
