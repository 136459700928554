import {
    InputFieldValueInput,
    InputFragment,
} from '../../../../../../lib/graphql/generated/types'
import { encodeInputFieldValue } from '../../../utils'

export const valuesToInputFieldValues = (
    values: any,
    inputFields: InputFragment[]
): InputFieldValueInput[] =>
    Object.entries(values)
        .filter(([identifier, _]) =>
            inputFields.find(f => f.identifier === identifier)
        )
        .map(([identifier, value]) => ({
            identifier,
            value: encodeInputFieldValue(value),
        }))

export default valuesToInputFieldValues
