/* istanbul ignore file */
'use client'

import { FC } from 'react'
import {
    RecipientType,
    UserQuery,
    ViewerUserQuery,
} from '../../../lib/graphql/generated/types'
import { useTranslation } from '../../../lib/hooks'
import { Button, ButtonTheme } from '../../base'
import { ContactForm, Masthead } from '../../common'
import ProfileAvatar from '../profileAvatar'
import ProfileMastheadImage from '../profileMastheadImage'
import styles from './profileEditMasthead.module.css'

interface Props {
    user: NonNullable<NonNullable<UserQuery['viewer']>['user']>
    viewerUser: NonNullable<
        NonNullable<ViewerUserQuery['viewer']>['viewerUser']
    >
}

const ProfileEditMasthead: FC<Props> = props => {
    const { t } = useTranslation()

    const name = props.user.displayName
    const avatarImage = props.user.thumbnail?.thumbnail?.resource
    const mastheadImage = props.user.image?.thumbnail?.resource

    return (
        <Masthead
            content={
                <>
                    <ProfileAvatar
                        firstName={props.user.firstName}
                        image={avatarImage}
                        lastName={props.user.lastName}
                    />
                    {props.user.id === props.viewerUser.id ? (
                        <Button
                            href="/profile/edit"
                            theme={ButtonTheme.Secondary}
                        >
                            {t('common:edit')}
                        </Button>
                    ) : (
                        <>
                            <ContactForm
                                email={props.viewerUser.email}
                                firstName={props.viewerUser.firstName}
                                lastName={props.viewerUser.lastName}
                                recipientId={props.user.id}
                                recipientType={RecipientType.User}
                            >
                                {open => (
                                    <Button
                                        onClick={open}
                                        theme={ButtonTheme.Secondary}
                                    >
                                        {t('user:contact')}
                                    </Button>
                                )}
                            </ContactForm>
                        </>
                    )}
                </>
            }
            contentClassName={styles.content}
            media={<ProfileMastheadImage alt={name} src={mastheadImage} />}
            title={name}
        />
    )
}

export default ProfileEditMasthead
