'use client'

import { Formik } from 'formik'
import { FormikErrors } from 'formik/dist/types'
import { set } from 'lodash'
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react'
import { useUpdateParticipantMutation } from '../../../../lib/graphql/generated/hooks'
import {
    BookingFragment,
    ParticipantFeatureSetting,
} from '../../../../lib/graphql/generated/types'
import { useNotification, useTranslation, useYup } from '../../../../lib/hooks'
import { useToken } from '../../../../lib/providers/tokenProvider/tokenProvider'
import { RegisterQueryEvent } from '../../../../lib/types'
import { Container, Text } from '../../../base'
import { ParticipantSummary } from '../../../common'
import Actions from '../actions'
import InputField from '../inputField'
import { InputFieldValueValues, OperationValues } from '../selection/selection'
import { getValidator } from '../utils'
import styles from './details.module.css'
import DetailsRepresentative from './detailsRepresentative'
import inputFieldsToValues from './utils/inputFieldsToValues'
import valuesToInputFieldValues from './utils/valuesToInputFieldValues'

interface Props {
    booking?: BookingFragment | null
    embedded?: boolean
    event: RegisterQueryEvent
    eventKey: string
    setStep: Dispatch<SetStateAction<number | undefined>>
    step: number
}

export interface CompanionValue {
    inputFieldValues: InputFieldValueValues
}

export interface Values {
    [identifier: string]: any
    representative: InputFieldValueValues | null
    representativeEnabled: boolean
}

const Details: FC<Props> = props => {
    const { t } = useTranslation()
    const notification = useNotification()
    const yup = useYup()
    const { token } = useToken()
    const [, updateParticipant] = useUpdateParticipantMutation()

    const onBack = useCallback(() => {
        props.setStep.call(undefined, 1)
    }, [props.setStep])

    const participantId = props.event.viewerParticipant?.id
    const representative = props.booking?.participant.representative ?? null

    const participantDefinitionInputFields = useMemo(
        () =>
            props.event.viewerRegistration?.participantDefinition.inputFields ??
            [],
        [props.event.viewerRegistration?.participantDefinition.inputFields]
    )

    const participantInputFieldValues =
        props.booking?.participant.inputFieldValues ?? []

    const registrationInputFields = useMemo(
        () => props.event.viewerRegistration?.inputFields ?? [],
        [props.event.viewerRegistration?.inputFields]
    )

    const bookingInputFieldValues = props.booking?.inputFieldValues ?? []

    const companionParticipantDefinitionInputFields = useMemo(
        () =>
            props.event.viewerRegistration?.participantDefinition
                .companionInputFields ?? [],
        [
            props.event.viewerRegistration?.participantDefinition
                .companionInputFields,
        ]
    )

    const representativeInputFieldValues =
        props.booking?.participant.representative?.inputFieldValues ?? []

    const onSubmit = useCallback(
        async (values: any) => {
            try {
                if (!token || !participantId || !props.booking) {
                    throw Error(t('common:error.internal'))
                }

                const {
                    data: updateParticipantData,
                    error: updateParticipantError,
                } = await updateParticipant(
                    {
                        token,
                        eventId: props.event.id,
                        inputFieldValues: valuesToInputFieldValues(
                            values,
                            participantDefinitionInputFields
                        ),
                        public: values.public ?? false,
                        representative: values.representativeEnabled
                            ? {
                                  inputFieldValues: valuesToInputFieldValues(
                                      values.representative,
                                      companionParticipantDefinitionInputFields
                                  ),
                              }
                            : undefined,
                        bookingInputFieldValues: valuesToInputFieldValues(
                            values,
                            registrationInputFields
                        ),
                        bookingId: props.booking.id,
                    },
                    { additionalTypenames: ['Booking'] }
                )

                if (updateParticipantError) {
                    throw updateParticipantError
                }

                if (
                    updateParticipantData?.viewer?.event?.viewerParticipant
                        ?.updateParticipant?.inputError
                ) {
                    throw Error(
                        updateParticipantData.viewer.event.viewerParticipant
                            .updateParticipant.inputError.message
                    )
                }

                props.setStep.call(undefined, prev => prev && prev + 1)
            } catch (e) {
                notification.alert(e.message)
            }
        },
        [
            companionParticipantDefinitionInputFields,
            notification,
            participantDefinitionInputFields,
            participantId,
            props.booking,
            props.event.id,
            props.setStep,
            registrationInputFields,
            t,
            token,
            updateParticipant,
        ]
    )

    const validate = useCallback(
        (values: Values) => {
            const errors: FormikErrors<OperationValues> = {}

            if (values.representativeEnabled && values.representative) {
                for (const [identifier, value] of Object.entries(
                    values.representative
                )) {
                    const inputField =
                        companionParticipantDefinitionInputFields.find(
                            f => f.identifier === identifier
                        )

                    if (!inputField) {
                        continue
                    }

                    const schema = getValidator(yup, inputField)

                    try {
                        schema.validateSync(value)
                    } catch (e) {
                        set(errors, `representative.${identifier}`, e.message)
                    }
                }
            }

            return errors
        },
        [companionParticipantDefinitionInputFields, yup]
    )

    return (
        <Formik<Values>
            initialValues={{
                ...inputFieldsToValues(
                    participantDefinitionInputFields,
                    participantInputFieldValues
                ),
                public: props.booking?.participant.public ?? false,
                ...inputFieldsToValues(
                    registrationInputFields,
                    bookingInputFieldValues
                ),

                representativeEnabled: representative !== null,
                representative: inputFieldsToValues(
                    companionParticipantDefinitionInputFields,
                    representativeInputFieldValues
                ),
            }}
            onSubmit={onSubmit}
            validate={validate}
            validationSchema={yup.object().shape({
                ...participantDefinitionInputFields.reduce(
                    (acc, inputField) => ({
                        ...acc,
                        [inputField.identifier]: getValidator(yup, inputField),
                    }),
                    {}
                ),
                ...registrationInputFields.reduce(
                    (acc, inputField) => ({
                        ...acc,
                        [inputField.identifier]: getValidator(yup, inputField),
                    }),
                    {}
                ),
            })}
            enableReinitialize
        >
            {formik => (
                <>
                    <Container className={styles.container}>
                        {props.event.viewerRegistration?.participantDefinition
                            .representative && (
                            <Text
                                className={styles.title}
                                element="h2"
                                type="h4"
                            >
                                {t('registration:information.contact')}
                            </Text>
                        )}
                        {participantDefinitionInputFields.length > 0 &&
                            participantDefinitionInputFields.map(inputField => {
                                return (
                                    <InputField
                                        key={inputField.identifier}
                                        inputField={inputField}
                                    />
                                )
                            })}
                        {participantDefinitionInputFields.length === 0 &&
                            props.booking?.participant && (
                                <ParticipantSummary
                                    participant={props.booking.participant}
                                />
                            )}
                        {registrationInputFields.length > 0 &&
                            registrationInputFields.map(inputField => {
                                return (
                                    <InputField
                                        key={inputField.identifier}
                                        inputField={inputField}
                                    />
                                )
                            })}
                        {props.event.viewerRegistration?.participantDefinition
                            .representative && (
                            <DetailsRepresentative
                                inputFields={
                                    props.event.viewerRegistration
                                        ?.participantDefinition
                                        .companionInputFields
                                }
                            />
                        )}
                        {ParticipantFeatureSetting.Disabled !==
                            props.event.viewerRegistration
                                ?.participantDefinition
                                .publicParticipantOptIn && (
                            <InputField
                                inputField={{
                                    label: t(
                                        'registration:visibleInParticipantList'
                                    ),
                                    identifier: 'public',
                                    __typename: 'CheckboxField',
                                    defaultCheckedValue:
                                        props.booking?.participant.public ??
                                        false,
                                    required: false,
                                }}
                            />
                        )}
                    </Container>
                    <Actions
                        bookingId={props.booking?.id}
                        disabled={formik.isSubmitting}
                        embedded={props.embedded}
                        eventId={props.event.id}
                        eventKey={props.event.key}
                        onBack={props.step > 1 ? onBack : undefined}
                        onSubmit={formik.submitForm}
                        submitButtonText={t('common:continue')}
                    />
                </>
            )}
        </Formik>
    )
}

export default Details
