'use client'

import { FC } from 'react'
import { Trans } from 'react-i18next'
import { useTranslation } from '../../../lib/hooks'
import FormattedCurrency from '../formattedCurrency'

interface Props {
    amount: number
    currency: string
    vatIncluded: boolean
    vatRate: number
}

const Price: FC<Props> = props => {
    const { t } = useTranslation()

    return (
        <Trans
            components={[
                <FormattedCurrency
                    key="0"
                    amount={props.amount}
                    currency={props.currency}
                />,
            ]}
            i18nKey={`common:price.${props.vatIncluded ? 'with' : 'without'}Vat`}
            t={t}
            values={{ vatRate: props.vatRate }}
        />
    )
}

export default Price
