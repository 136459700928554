'use client'

import { Formik } from 'formik'
import { FormikErrors } from 'formik/dist/types'
import { set } from 'lodash'
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react'
import { useUpdateBookingMutation } from '../../../../lib/graphql/generated/hooks'
import {
    BookingFragment,
    BookingOperationCreateInput,
    CompanionBookingOperationCreateInput,
} from '../../../../lib/graphql/generated/types'
import { useNotification, useTranslation, useYup } from '../../../../lib/hooks'
import { useToken } from '../../../../lib/providers/tokenProvider/tokenProvider'
import { RegisterQueryEvent } from '../../../../lib/types'
import { Container, FormattedHtml } from '../../../base'
import Actions from '../actions'
import { getValidator } from '../utils'
import BookingSection from './bookingSection'
import Preselector from './preselector'
import styles from './selection.module.css'
import { bookingToValues, valuesToBookingOperations } from './utils'
import valuesToCompanions from './utils/valuesToCompanions'

export interface Values {
    companions: CompanionValues
    operations: OperationValues
}

export interface OperationValues {
    [operationId: string]: OperationValue[]
}

export interface OperationValue
    extends Pick<
        BookingOperationCreateInput,
        'groupBookingId' | 'newGroupBooking' | 'companionId'
    > {
    companion?: undefined // Dummy field vor validation
    inputFieldValues: InputFieldValueValues
}
export interface CompanionValues {
    [companionId: string]: CompanionValue
}

export interface CompanionValue
    extends Pick<CompanionBookingOperationCreateInput, 'id'> {
    displayName?: string
    inputFieldValues: InputFieldValueValues
    persisted: boolean
}

export interface InputFieldValueValues {
    [identifier: string]: string
}

interface Props {
    booking?: BookingFragment | null
    createBooking: (
        eventId: string,
        operations: BookingOperationCreateInput[],
        companions: CompanionBookingOperationCreateInput[]
    ) => Promise<void>
    embedded?: boolean
    event: RegisterQueryEvent
    eventKey: string
    executeRegisterQuery: () => void
    setStep: Dispatch<SetStateAction<number | undefined>>
    token?: string
}

const Selection: FC<Props> = props => {
    const { t } = useTranslation()
    const notification = useNotification()
    const { token } = useToken()
    const yup = useYup()
    const [, updateBooking] = useUpdateBookingMutation()
    const { createBooking } = props

    const _updateBooking = useCallback(
        async (
            operations: BookingOperationCreateInput[],
            companions: CompanionBookingOperationCreateInput[]
        ) => {
            if (!token || !props.booking) {
                throw Error(t('common:error.internal'))
            }

            const { data, error } = await updateBooking({
                token,
                eventId: props.event.id,
                bookingId: props.booking.id,
                operations,
                companions,
            })

            if (error) {
                throw error
            }

            if (
                data?.viewer?.event?.viewerParticipant?.updateBooking
                    ?.inputError
            ) {
                throw Error(
                    data.viewer.event.viewerParticipant.updateBooking.inputError
                        .message
                )
            }

            props.setStep.call(undefined, 2)
        },
        [props.booking, props.event.id, props.setStep, t, token, updateBooking]
    )

    const onSubmit = useCallback(
        async (values: Values) => {
            const operations = valuesToBookingOperations(values.operations)
            const companions = valuesToCompanions(values.companions)

            try {
                if (props.booking) {
                    await _updateBooking(operations, companions)
                } else {
                    await createBooking(props.event.id, operations, companions)
                    props.setStep.call(undefined, 2)
                }
            } catch (e) {
                notification.alert(e.message)
            }
        },
        [
            createBooking,
            _updateBooking,
            notification,
            props.booking,
            props.event.id,
            props.setStep,
        ]
    )

    const sections = useMemo(
        () => [props.event, ...(props.event.subEvents ?? [])],
        [props.event]
    )

    const validate = useCallback(
        (values: Values) => {
            const errors: FormikErrors<OperationValues> = {}

            for (const section of sections) {
                if (!Array.isArray(section.operations)) {
                    continue
                }

                for (const operation of section.operations) {
                    const bookingOperations = values.operations[operation.id]

                    if (!Array.isArray(bookingOperations)) {
                        continue
                    }

                    const maxCompanions =
                        operation?.viewerBookable?.maximumCompanions ?? 0
                    const count = Object.values(
                        values.operations[operation.id]
                    ).filter(value => value.companionId !== undefined).length
                    if (count > maxCompanions) {
                        set(
                            errors,
                            `operations.${operation.id}[0].companion`,
                            t('companion:validation:maxCompanions', {
                                count: maxCompanions,
                            })
                        )
                    }

                    for (let i = 0; i < bookingOperations.length; i++) {
                        if (
                            operation.groupBookingDefinition &&
                            !bookingOperations[i].newGroupBooking &&
                            !bookingOperations[i].groupBookingId
                        ) {
                            set(
                                errors,
                                `operations.${operation.id}[0].groupBookingId`,
                                operation.groupBookings?.itemCount
                                    ? t('common:error.required')
                                    : t('groupBooking:newGroupRequired')
                            )
                        }

                        for (const [identifier, value] of Object.entries(
                            bookingOperations[i].inputFieldValues
                        )) {
                            const inputField = operation.inputFields.find(
                                f => f.identifier === identifier
                            )

                            if (!inputField) {
                                continue
                            }

                            const schema = getValidator(yup, inputField)

                            try {
                                schema.validateSync(value)
                            } catch (e) {
                                set(
                                    errors,
                                    `operations.${operation.id}[${i}].inputFieldValues.${identifier}`,
                                    e.message
                                )
                            }
                        }
                    }
                }
            }

            return errors
        },
        [sections, t, yup]
    )

    const note = props.event.viewerRegistration?.note

    const companionInputFields = useMemo(
        () =>
            props.event.viewerRegistration?.participantDefinition
                .companionInputFields ?? [],
        [
            props.event.viewerRegistration?.participantDefinition
                .companionInputFields,
        ]
    )

    return (
        <Formik<Values>
            initialValues={bookingToValues(
                props.booking,
                props.event.viewerParticipant?.companions
            )}
            onSubmit={onSubmit}
            validate={validate}
            enableReinitialize
        >
            {formik => (
                <>
                    <Preselector sections={sections} />
                    <Container className={styles.container}>
                        {note && <FormattedHtml html={note} />}
                        {sections.map(section => (
                            <BookingSection
                                key={section.id}
                                companionInputFields={companionInputFields}
                                event={props.event}
                                eventKey={props.eventKey}
                                section={section}
                            />
                        ))}
                    </Container>
                    <Actions
                        bookingId={props.booking?.id}
                        disabled={formik.isSubmitting}
                        embedded={props.embedded}
                        eventId={props.event.id}
                        eventKey={props.event.key}
                        onSubmit={formik.submitForm}
                        submitButtonText={t('common:continue')}
                        reset
                    />
                </>
            )}
        </Formik>
    )
}

export default Selection
