'use client'

import { FC, ReactNode, useCallback, useMemo, useState } from 'react'
import { BookingFragment } from '../../../../lib/graphql/generated/types'
import { useTranslation } from '../../../../lib/hooks'
import { Dialog, Tab, Tabs } from '../../../base'
import ActiveParticipantsForm from './activeParticipantsForm'
import GroupForm from './groupForm'
import InvitedParticipantsForm from './invitedParticipantsForm'

export interface Values {
    description: string
    public: boolean
}

interface Props {
    children: (open: () => void) => ReactNode
    eventId: string
    groupBooking: NonNullable<
        BookingFragment['groupedBookingOperations'][number]['bookingOperations'][number]['groupBooking']
    >
    loading?: boolean
    maxParticipants?: number
}

const GroupBookingUpdateDialog: FC<Props> = props => {
    const { t } = useTranslation()
    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogClosed, setDialogClosed] = useState(true)

    const openDialog = useCallback(() => {
        setDialogOpen(true)
        setDialogClosed(false)
    }, [])

    const closeDialog = useCallback(() => setDialogOpen(false), [])

    const onDialogClosed = useCallback(() => setDialogClosed(true), [])

    const primaryParticipant = props.groupBooking.primaryParticipant.displayName

    const activeParticipants = useMemo(
        () =>
            props.groupBooking.participants
                ?.filter(
                    participant =>
                        participant.id !==
                        props.groupBooking.primaryParticipant.id
                )
                .map(participant => ({
                    id: participant.id,
                    name: participant.displayName,
                })) ?? [],
        [
            props.groupBooking.participants,
            props.groupBooking.primaryParticipant.id,
        ]
    )

    const invitedParticipants = useMemo(
        () =>
            props.groupBooking.invitedParticipants
                ?.filter(
                    participant =>
                        !props.groupBooking.participants?.some(
                            p => p.id === participant.id
                        )
                )
                .map(participant => ({
                    id: participant.id,
                    name: participant.displayName,
                })) ?? [],
        [
            props.groupBooking.invitedParticipants,
            props.groupBooking.participants,
        ]
    )

    return (
        <>
            {props.children(openDialog)}
            <Dialog
                onClose={closeDialog}
                onClosed={onDialogClosed}
                open={dialogOpen}
                title={t('groupBooking:edit')}
                small
            >
                <Tabs>
                    <Tab label={t('groupBooking:group')}>
                        <GroupForm
                            key={String(dialogClosed)}
                            closeDialog={closeDialog}
                            description={props.groupBooking.description}
                            eventId={props.eventId}
                            groupBookingId={props.groupBooking.id}
                            loading={props.loading}
                            name={props.groupBooking.name}
                            public={props.groupBooking.public}
                        />
                    </Tab>
                    <Tab
                        label={`${t('groupBooking:participants')} (${
                            1 + activeParticipants.length
                        }${
                            props.maxParticipants
                                ? `/${props.maxParticipants}`
                                : ''
                        })`}
                    >
                        <ActiveParticipantsForm
                            activeParticipants={activeParticipants}
                            closeDialog={closeDialog}
                            eventId={props.eventId}
                            groupBookingId={props.groupBooking.id}
                            loadingBooking={props.loading}
                            maxParticipants={props.maxParticipants}
                            primaryParticipant={primaryParticipant}
                        />
                    </Tab>
                    <Tab
                        label={`${t('groupBooking:invited')} (${
                            invitedParticipants.length
                        })`}
                    >
                        <InvitedParticipantsForm
                            closeDialog={closeDialog}
                            eventId={props.eventId}
                            groupBookingId={props.groupBooking.id}
                            invitedParticipants={invitedParticipants}
                            loadingBooking={props.loading}
                        />
                    </Tab>
                </Tabs>
            </Dialog>
        </>
    )
}

export default GroupBookingUpdateDialog
