import clsx from 'clsx'
import { FC } from 'react'
import { BookingFragment } from '../../../lib/graphql/generated/types'
import FormattedInputFieldValue from '../formattedInputFieldValue'
import styles from './participantSummary.module.css'

interface Props {
    bookingInputFieldValues?: BookingFragment['inputFieldValues']
    className?: string
    participant: BookingFragment['participant']
}

const ParticipantSummary: FC<Props> = props => {
    return (
        <div className={clsx(styles.root, props.className)}>
            {props.participant.inputFieldValues.length ? (
                props.participant.inputFieldValues.map(inputFieldValue => (
                    <FormattedInputFieldValue
                        key={inputFieldValue.inputField.identifier}
                        inputFieldValue={inputFieldValue}
                    />
                ))
            ) : (
                <div className={styles.participant}>
                    <div>{props.participant.displayName}</div>
                    <div>{props.participant.email}</div>
                </div>
            )}
            {props.bookingInputFieldValues?.map(inputFieldValue => (
                <FormattedInputFieldValue
                    key={inputFieldValue.inputField.identifier}
                    inputFieldValue={inputFieldValue}
                />
            ))}
        </div>
    )
}

export default ParticipantSummary
