import { Layout } from '../../graphql/generated/types'
import { EventQueryEvent } from '../../types'

interface ResponsiveImageSet {
    large: string
    largeWidth: number
    medium: string
    mediumWidth: number
    sizes: string
    small: string
    smallWidth: number
}

const responsiveImageSet = (event: EventQueryEvent): ResponsiveImageSet => {
    if (event.layout === Layout.Wide) {
        // Wide layout
        return {
            small: event.wideImage?.small?.resource || '', // ~600px width
            medium: event.wideImage?.medium?.resource || '', // ~1200px width
            large: event.wideImage?.large?.resource || '', // ~2712px width
            smallWidth: 600,
            mediumWidth: 1200,
            largeWidth: 2712,
            sizes: '(max-width: 768px) 100vw, (max-width: 1200px) 90vw, 2712px',
        }
    } else {
        // Standard layout
        return {
            small: event.image?.small?.resource || '', // ~480px width
            medium: event.image?.medium?.resource || '', // ~960px width
            large: event.image?.large?.resource || '', // ~1650px width
            smallWidth: 480,
            mediumWidth: 960,
            largeWidth: 1650,
            sizes: '(max-width: 768px) 100vw, (max-width: 1200px) 90vw, 1650px',
        }
    }
}

export default responsiveImageSet
